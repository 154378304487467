import React from 'react'
import Vimeo from '@u-wave/react-vimeo';

import { 
	Typography, 
} from '@material-ui/core'


// Start of Image Componenent
export const ElementImageView = ({ attachment, submitStudentElementResponse }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<img 
			src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} 
			alt="Element" 
			className="d-block rounded mx-auto w-50"
		/>
	</>
}
// End of Image Component


// Start of Audio Component
export const ElementAudioView = ({ attachment, submitStudentElementResponse, setElements }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<div>
			<audio 
				controls="controls" 
				className="w-75 d-block mx-auto"
				onEnded={async () => {
					try {
						const { status, data: { elements_query } } = await submitStudentElementResponse();

						if(status === 200) {
							setElements(elements_query);
							window.scrollTo(0,document.body.scrollHeight);
							return;
						}

					} catch(err) {
						console.log(err);
					}
				}}
			>
				<source src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} />
				Your browser does not support the audio element.
			</audio>
		</div>
	</>
}
// End of Audio Component

// Start of Document Component
export const ElementDocumentView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<div>
			<div className="text-center">
				<label htmlFor="document_input" className="text-center w-100 m-0 p-0 btn">
					<img
						width="50"
						src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
						alt="document"
						className="d-block mx-auto"
						style={{ maxHeight: "20vh" }}
					/>
				</label>
				<a
					href={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name}
					download={attachment.file_name}
					className="btn p-0"
				>
					<span className="text-primary">{attachment.file_name}</span>
					<br />
					<span>Click to download!</span>
				</a>
			</div>
		</div>
	</>
}
// End of Document Component

// Start of Video Component
export const ElementVideoView = ({ attachment, submitStudentElementResponse, setElements }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}

		{(() => {
			if(Boolean((attachment.context.video_link ?? "").includes("https://player.vimeo.com/video/") || 
				(attachment.context.video_link ?? "").includes("https://vimeo.com/"))) {
				return (
					<div>
						<Vimeo
							style={{ borderRadius: 20, overflow: "hidden" }}
							className="rounded w-75 mx-auto"
							video={attachment.context.video_link}
							showTitle
							responsive={true}
							onEnd={async () => {
								try {
									const { status, data: { elements_query } } = await submitStudentElementResponse();

									if(status === 200) {
										setElements(elements_query);
										window.scrollTo(0,document.body.scrollHeight);
										return;
									}

								} catch(err) {
									console.log(err);
								}
							}}
						/>
					</div>
				);

			} else if(Boolean((attachment.context.video_link ?? "").includes("https://www.youtube.com")) || 
				Boolean((attachment.context.video_link ?? "").includes("https://youtu.be/"))) {
				return (
					<div className="w-75 mx-auto">
						{/* <Youtube
							className="rounded w-100 mx-auto"
							video={
							(
								attachment.context.video_link
								.replace("https://www.youtube.com/embed/", "")
								.replace("https://youtu.be/", "")
								.replace("https://www.youtube.com/watch?v=", "")
								).slice(0, 11)
							}
							showInfo
							height={400}
							responsive={true}
						/> */}

						Youtube is coming soon...
					</div>
				);
			
			}
		})()}
	</>
}
// End of Video Component

// Start of Text Component
export const ElementTextView = ({ attachment }) => {
	return <>
		{Boolean(attachment.context.text_content) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.context.text_content}
				</Typography> 
			</div>
		)}
	</>
}
// End of Text Component

// Start of Reflection Component
export const ElementReflectionView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/reflection_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.context.is_shared === "shared" ? "Shared: ": "Private: "} How does this apply to you?
			</Typography> 
		</div>

		<hr />
	</>
}
// End of Reflection Component

// Start of Signature Component
export const ElementSignatureView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Signature" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/signature_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title}
			</Typography> 
		</div>

		<hr />
	</>
}
// End of Signature Component

// Start of Survey Component
export const ElementSurveyView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/survey_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Let's take a Survey"}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{attachment.description}
				</Typography> 
			</div>
		)}
	</>
}
// End of Survey Component