import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';


import { OwnerLessonDetails } from "./OwnerLessonDetails";
import { StudentLessonDetails } from './StudentLessonDetails';
import { MentorLessonDetails } from './MentorLessonDetails';

export const LessonDetails = ({ currentRole, user, match: { params } }) => {
    switch(currentRole.id) {
        case 1: 
            return <OwnerLessonDetails params={params} />
        case 2: 
            return <StudentLessonDetails params={params} user={user} />
        case 3: 
            return <MentorLessonDetails params={params} user={user} />
        default:
            return <Redirect to="/" />
    }
}

const mapStateToProps = ({ currentValues: { currentRole }, auth: { user } }) => ({ currentRole, user })

export default connect(mapStateToProps)(LessonDetails)
