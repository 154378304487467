import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Formik } from 'formik';
import { Redirect, Link } from 'react-router-dom';
import { useSnackbar } from "notistack";

import {
	Tabs, 
	Tab,
	Typography,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
} from "@material-ui/core";

import { useSubheader } from '../../../../../_metronic/layout';
import { DoughnutChart } from '../../../../widgets/charts';

import { get_student_lesson_details, get_student_lesson_content, submit_student_element_response, get_student_library_content, get_student_library_details } from '../../_redux/LibrariesCrud';

import { 
	ElementAudioView, 
	ElementImageView, 
	ElementDocumentView,
	ElementVideoView,
	ElementTextView,
	ElementReflectionView,
	ElementSignatureView,
	ElementSurveyView
} from './components/StudentLessonElements';
import { Spinner } from 'react-bootstrap';
import { ShowResponses, SubmitLessonQuestion } from './components/LessonContentModals';

export const StudentLessonDetails = injectIntl(({ params, intl, user }) => {
	const [init, setInit] = useState(false);
	const [lesson, setLesson] = useState({});
	const [elements, setElements] = useState([]);
	const [recentLessonTab, setRecentLessonTab] = useState("Lesson Details");
	const [elementIsSubmitting, setElementIsSubmitting] = useState(false);
	const [accessDenied, setAccessDenied] = useState(false);
    const [libraryContent, setLibraryContent] = useState([]);
    const [library, setLibrary] = useState({});
    const [openLessonQuestion, setOpenLessonQuestion] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		setInit(false);
		get_student_lesson_details(params.library_party_id, params.module_resource_id, params.lesson_resource_id).then(({ data: { lesson_query } }) => {
			if(lesson_query) {
				setLesson(lesson_query);
			}
			setInit(true);
		}).catch((err) => {
			console.log(err);
			setAccessDenied(true);
		});

		get_student_lesson_content(params.library_party_id, params.module_resource_id, params.lesson_resource_id).then(({ data: { elements_query } }) => {
			if(elements_query) {
				setElements(elements_query);
			}
			setInit(true);
		}).catch(err => console.log(err));

		get_student_library_content(params.library_party_id).then(({ data: { library_content_query } }) => {
            if(library_content_query) {
                setLibraryContent(library_content_query);
            }
            setInit(true);
        }).catch(err => console.log(err));

		get_student_library_details(params.library_party_id).then(({ data: { library_query } }) => {
            if(library_query) {
                setLibrary(library_query);
            }
            setInit(true);
        }).catch(err => {
            console.log(err);
            setAccessDenied(true);
        })

	}, [params.library_party_id, params.module_resource_id, params.lesson_resource_id]);

	const suhbeader = useSubheader();
	suhbeader.setTitle(lesson.title || "Lesson");

	if(accessDenied) {
        snackbarAlert("error", "Access Denied");
		return <Redirect to="/" />
	}

	if(!init || !library.title) {
		return <Spinner animation="grow" />
	}

	return <>
		<SubmitLessonQuestion lesson={lesson} openLessonQuestion={openLessonQuestion} setOpenLessonQuestion={setOpenLessonQuestion}  />

		<div className="row">
			<div className="col-12">
				<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
					<div className="card-header">
						<h3 className="card-title font-weight-bolder">{lesson.title}</h3>
					</div>
					<div className="card-body">
						<div className="d-flex justify-content-center">
							<Tabs
								className="mb-3"
								value={recentLessonTab}
								onChange={(e, newValue) => {
									setRecentLessonTab(newValue);
								}}
								indicatorColor="secondary"
								textColor="secondary"
								variant="scrollable"
								// variant="fullWidth"
							>
								<Tab value="Overview" label="Overview" />
								<Tab value="Training Details" label="Training Details" />
								<Tab value="Lesson Details" label="Lesson Details" />
								<Tab value="Q & A" label="Q & A" />
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</div>

		{(() => {
			if(recentLessonTab === "Lesson Details") {
				return <>
					<div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-body">

									{(() => elements.map((indiv_element, element_index) => {
										return <div className="mb-5" key={element_index}>
											<div className="border rounded p-3 bg-white shadow">
												{Boolean(indiv_element.description) && (
													<div>
														<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
															{indiv_element.description}
														</Typography> 

														<hr />

													</div>
												)}

												{indiv_element.attachments.map((indiv_attachment, attachment_index) => {

													const submitStudentElementResponse = async (values) => {
														let request_body = {
															library_party_id: params.library_party_id,
															module_resource_id: params.module_resource_id,
															lesson_resource_id: params.lesson_resource_id,
															element_id: indiv_element.id,
															element_attachment_id: indiv_attachment.id,
														}

														if(values) {
															request_body.student_response = values.student_response;
														}

														if(indiv_element.student_element_progress.is_completed) {
															return { status: null, data: { elements_query: null } };
														}

														if(indiv_element.element_type_id === 8) {
															request_body.attachment_additional_id = values.attachment_additional_id;
															request_body.student_additional_response = values.student_additional_response;
														}
														let response = await submit_student_element_response(request_body);
														setElementIsSubmitting(false);
														snackbarAlert("success", "Element Completed");
														return response;
													}

													switch(indiv_attachment.attachment_type_id) {
														case 1: 
															return <div key={attachment_index}>
																<ElementImageView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	className="btn btn-success btn-pill d-block mx-auto"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	Next!
																</button>}
															</div>
														case 2: 
															return <div key={attachment_index}>
																<ElementAudioView 
																	submitStudentElementResponse={submitStudentElementResponse} 
																	attachment={indiv_attachment}
																	setElements={setElements} 
																/>

																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <Typography align='center' className="text-info">
																	Please play the recordings to complete.
																</Typography>}
															</div>
														case 3: 
															return <div key={attachment_index}>
																<ElementDocumentView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	className="btn btn-success btn-pill d-block mx-auto"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();
																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	Next!
																</button>}
															</div>
														case 4: 
															return <div key={attachment_index}>
																<ElementVideoView 
																	submitStudentElementResponse={submitStudentElementResponse} 
																	attachment={indiv_attachment}
																	setElements={setElements} 
																/>

																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <Typography align='center' className="text-info">
																	Please play the video to complete.
																</Typography>}
															</div>
														case 5: 
															return <div key={attachment_index}>
																<ElementTextView attachment={indiv_attachment} />
																<hr />
																
																{Boolean(indiv_element.student_element_progress.is_completed) ? <Typography align='center' className="text-success">
																	Completed
																</Typography> : <button 
																	disabled={elementIsSubmitting}
																	type="button"
																	className="btn btn-success btn-pill d-block mx-auto"
																	onClick={async () => {
																		setElementIsSubmitting(true);

																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse();

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																			setElementIsSubmitting(false);
																		} catch(err) {
																			console.log(err);
																			setElementIsSubmitting(false);
																		}
																	}}
																>
																	Next!
																</button>}
															</div>
														case 6: 
															return <div key={attachment_index}>
																<ElementReflectionView 
																	submitStudentElementResponse={submitStudentElementResponse} 
																	attachment={indiv_attachment}
																	setElements={setElements} 
																	element={indiv_element}
																/>

																{Boolean(indiv_element.student_element_progress.is_completed) ? <>
																	<Typography align='center'>
																		<span className="font-weight-bold">Your Response: </span>
																		{indiv_attachment.student_attachment_response.content}
																	</Typography>

																	{indiv_attachment.context.is_shared === "shared" && <ShowResponses params={params} element={indiv_element} attachment={indiv_attachment} />}

																	<hr />
																	<Typography align='center' className="text-success">
																		Completed
																	</Typography>
																</> : 
																	
																<Formik
																	initialValues={{
																		student_response: "",
																	}}
																	validate={values => {
																		const errors = {};

																		if (!values.student_response) {
																			errors.student_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.REQUIRED_FIELD"
																			});
																		} 

																		return errors;
																	}}
																	onSubmit={async (values, { setSubmitting, resetForm }) => {
																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse(values);

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue,
																		handleReset
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																		>
																			
																			{Boolean(indiv_attachment.description) && <>
																				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
																					Description:
																				</Typography>
																				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
																					{indiv_attachment.description}
																				</Typography>
																			</>}

																			<div className="form-group">
																				<TextField
																					label="Your Response"
																					color="secondary"
																					multiline
																					name="student_response"
																					placeholder="Your response could be multiple lines..."
																					margin="normal"
																					variant="outlined"
																					onBlur={handleBlur}
																					onChange={handleChange}
																					value={values.student_response}
																					helperText={touched.student_response && errors.student_response}
																					error={Boolean(touched.student_response && errors.student_response)}
																					InputLabelProps={{
																						shrink: true,
																					}}
																				/>
																			</div>


																			<Typography align='center' className="text-info">
																				<button
																					type="submit"
																					disabled={isSubmitting}
																					className="btn btn-success"
																				>
																					Submit
																				</button>
																			</Typography>
																		</form>
																	)}
																</Formik>}
															</div>
														case 7: 
															return <div key={attachment_index}>
																<ElementSignatureView attachment={indiv_attachment} />

																{Boolean(indiv_element.student_element_progress.is_completed) ? <>
																	<Typography align='center'>
																		<span className="font-weight-bold">Signed by: </span>
																		{indiv_attachment.student_attachment_response.content}
																	</Typography>

																	<hr />
																	<Typography align='center' className="text-success">
																		Completed
																	</Typography>
																</> : 
																	
																<Formik
																	initialValues={{
																		student_response: "",
																	}}
																	validate={values => {
																		const errors = {};

																		if (!values.student_response) {
																			errors.student_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.INVALID_FIELD"
																			});
																		} else if(values.student_response.toLowerCase() !== (user.firstname + " " + user.lastname).toLowerCase()) {
																			errors.student_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.INVALID_FIELD"
																			});
																		}

																		return errors;
																	}}
																	onSubmit={async (values, { setSubmitting, resetForm }) => {
																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse(values);

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue,
																		handleReset
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																		>
																			
																			{Boolean(indiv_attachment.description) && <>
																				<Typography variant="body1" className="font-weight-bolder">
																					Description:
																				</Typography>
																				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
																					{indiv_attachment.description}
																				</Typography>
																			</>}

																			<div className="form-group">
																				<TextField
																					label="Your Full Name"
																					color="secondary"
																					name="student_response"
																					placeholder="Ex. Juan Dela Cruz"
																					margin="normal"
																					onBlur={handleBlur}
																					onChange={handleChange}
																					value={values.student_response}
																					helperText={touched.student_response && errors.student_response}
																					error={Boolean(touched.student_response && errors.student_response)}
																					InputLabelProps={{
																						shrink: true,
																					}}
																				/>
																			</div>


																			<Typography align='center' className="text-info">
																				<button
																					type="submit"
																					disabled={isSubmitting}
																					className="btn btn-success"
																				>
																					Submit
																				</button>
																			</Typography>
																		</form>
																	)}
																</Formik>}
															</div>
														case 8: 
															return <div key={attachment_index}>
																<ElementSurveyView attachment={indiv_attachment} />

																{Boolean(indiv_element.student_element_progress.is_completed) ? <>
																	<div className="row">
																		<div className="col-lg-4 d-flex align-items-center justify-content-center">
																			<Typography>
																				<span className="font-weight-bold">You've chosen: </span>
																				{indiv_attachment.student_attachment_response.content}
																			</Typography>
																		</div>
																		
																		<div className="col-lg-8">
																			<DoughnutChart 
																				chartLabel="Survey Data" 
																				dataLabel={indiv_attachment.attachment_additionals.map(indiv_additional => {
																					return indiv_additional.description;
																				})}
																				data={indiv_attachment.attachment_additionals.map(indiv_additional => {
																					return indiv_additional.responses ?? 0;
																				})}
																			/>
																		</div>
																		
																	</div>
																	
																	

																	<hr />

																	<Typography align='center' className="text-success">
																		Completed
																	</Typography>
																</> : 

																<Formik
																	initialValues={{
																		attachment_additional_id: indiv_attachment.id,
																		student_response: "",
																		student_additional_response: "",
																	}}
																	validate={values => {
																		const errors = {};

																		if (!values.student_additional_response) {
																			errors.student_additional_response = intl.formatMessage({
																				id: "AUTH.VALIDATION.INVALID_FIELD"
																			});
																		}

																		return errors;
																	}}
																	onSubmit={async (values, { setSubmitting, resetForm }) => {
																		try {
																			const { status, data: { elements_query } } = await submitStudentElementResponse(values);

																			if(status === 200) {
																				setElements(elements_query);
																				window.scrollTo(0,document.body.scrollHeight);
																				return;
																			}

																		} catch(err) {
																			console.log(err);
																		}
																	}}
																>
																	{({
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		handleSubmit,
																		isSubmitting,
																		setFieldValue,
																		handleReset
																	}) => (
																		<form
																			onSubmit={handleSubmit}
																			noValidate
																			autoComplete="off"
																		>
																			
																			{Boolean(indiv_attachment.description) && <>
																				<Typography variant="body1" className="font-weight-bolder">
																					Description:
																				</Typography>
																				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
																					{indiv_attachment.description}
																				</Typography>
																			</>}

																			<Typography variant="body1" className="font-weight-bolder">
																				Choices:
																			</Typography>
																			
																			<RadioGroup 
																				aria-label="choices" 
																				name="student_additional_response" 
																				className="ml-5"
																				value={values.student_additional_response} 
																				onChange={async e => {
																					await setFieldValue("student_additional_response", e.target.value);
																					await handleSubmit();
																				}}
																			>
																				{indiv_attachment.attachment_additionals.map((indiv_additional) => {
																					return <FormControlLabel 
																						key={indiv_additional.id}
																						disabled={isSubmitting} 
																						value={indiv_additional.id.toString()} 
																						control={<Radio />} 
																						label={indiv_additional.description} 
																						onClick={() => {
																							setFieldValue("student_response", indiv_additional.description);
																						}}
																					/>
																				})}
																			</RadioGroup>
																		</form>
																	)}
																</Formik>}
															</div>
														default:
															return null;
													}
												})}
											</div>
										</div>
									}))()}
								</div>
							</div>
						</div>
					</div>
				</>
			} else if(recentLessonTab === "Training Details") {
				return <div className="row">
					<div className="col-12">
						<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
							<div className="card-header">
								<h3 className="card-title font-weight-bolder">{library.settings.group_content ? "Modules and Lessons" : "Library Lessons"}</h3>
							</div>

							<div className="card-body">
								{(() => {
									if(library.access_denied) {
										return <Typography variant="h4" align="center">
											Please contact your admin to access this library.
										</Typography>
									} else {
										return libraryContent.length ? libraryContent.map((indiv_module, module_index) => {
											if(library.settings.group_content) {
												return (
													<div key={module_index} className="form-group">
														<div className="p-3 bg-secondary d-flex rounded mb-2 align-items-center">
															<Typography className="font-weight-bolder">
																{indiv_module.title}
															</Typography>
														</div>
														{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
															return (
																<div key={lesson_index} className="d-flex align-items-center ml-5 mb-2 border-bottom">
																	<div>
																		{!library.access_expired ? <Link 
																			to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																			className="text-primary"
																			onClick={() => setRecentLessonTab("Lesson Details")}
																		>
																			{indiv_lesson.title}
																		</Link> : <Typography>
																			{indiv_lesson.title}
																		</Typography>}
																		
																		{Boolean(indiv_lesson.progress_report) && <Typography variant="subtitle2">
																			Elements completed {indiv_lesson.progress_report.elements_completed}
																		</Typography>}
																	</div>
																	
																	{!library.access_expired && <Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary ml-auto mr-3"
																		onClick={() => setRecentLessonTab("Lesson Details")}
																	>
																		View
																	</Link>}
																</div>
															);
														}) : (
															<div className="d-flex ml-5 mb-2 border-bottom">
																No lesson/s yet...
															</div>
														)}
													</div>
												)
											} else {
												return <div key={module_index}>
													{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
														return (
															<div key={lesson_index} className="d-flex align-items-center mb-2 border-bottom">
																<div>
																	{!library.access_expired ? <Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary"
																		onClick={() => setRecentLessonTab("Lesson Details")}
																	>
																		{indiv_lesson.title}
																	</Link> : <Typography>
																		{indiv_lesson.title}
																	</Typography>}
																	
																	{Boolean(indiv_lesson.progress_report) && <Typography variant="subtitle2">
																		Elements completed {indiv_lesson.progress_report.elements_completed}
																	</Typography>}
																</div>
																
																{!library.access_expired && <Link 
																	to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																	className="text-primary ml-auto mr-3"
																	onClick={() => setRecentLessonTab("Lesson Details")}
																>
																	View
																</Link>}
															</div>
														);
													}) : (
														<div className="d-flex ml-5 mb-2 border-bottom">
															No lesson/s yet...
														</div>
													)}
												</div>
											}
										}) : (
											<div className="text-center">
												No content yet...
											</div>
										)
									}
								})()}
							</div>
						</div>
					</div>
				</div>
			} else {
				setRecentLessonTab("Lesson Details");
			}
		})()}
	</>
})
