/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {forwardRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';



import * as persistCurrentValues from "../../../../../redux/ducks/currentValues.duck";
import {toAbsoluteUrl} from "../../../../_helpers";

const QuickActionsDropdownToggle = forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      href="#"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
      id="kt_subheader_quick_actions"
      className="btn btn-sm btn-clean"
    >
      <span className="mr-3">View as: ({props.currentRole && props.currentRole.name})</span> 
      <span className="svg-icon svg-icon-primary svg-icon-lg">
        <span className="svg-icon-primary svg-icon-2x">
          <SVG  src={toAbsoluteUrl("/media/svg/icons/General/Binocular.svg")} />
        </span>
      </span>
    </a>
  );
});

const QuickActions = ({ user, currentRole, setCurrentRole }) => {
  return (
      <>
        <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="quick-actions-tooltip">Change UI View</Tooltip>}
        >
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
                as={QuickActionsDropdownToggle}
                id="dropdown-toggle-quick-actions-subheader"
                currentRole={currentRole}
            />
            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
              <ul className="navi navi-hover">
                <li className="navi-header font-weight-bold py-5">
                    <span className="font-size-lg">Choose Your View:</span>
                    <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                      title="Change the current view of the UI"></i>
                </li>
                <li className="navi-separator mb-3 opacity-70"></li>

                {user.roles.map((indiv_role, role_index) => {
                  return (
                    <li className="navi-item" onClick={() => setCurrentRole(indiv_role)} key={role_index}>
                      <div className="navi-link">
                        <span className="navi-text">
                          <button
                            type="button"
                            className={`border-0 label label-xl label-inline label-light-${currentRole && indiv_role.id === currentRole.id ? "success" : "grey"} text-dark w-100`}
                          >
                            {indiv_role.name}
                          </button>
                        </span>
                      </div>
                    </li>
                  )
                })}
                
                
                {Boolean(user.roles.find(element => element.id === 1)) && <>
                  <li className="navi-separator mt-3 opacity-70"></li>
                  <li className="navi-footer pt-5 pb-4">
                      <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                          <i className="ki ki-plus icon-sm"></i>
                          Add new
                      </a>
                  </li>
                </>}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
      </>
  );
}

const mapStateToProps = ({ currentValues: { currentRole }, auth: { user } }) => ({
  currentRole,
  user
})

export default connect(mapStateToProps, persistCurrentValues.actions)(QuickActions);