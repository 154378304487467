import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
	SetCurrentRole: "[Set Current Role] Action",
};

const initialState = {
	currentRole: undefined,
};

export const reducer = persistReducer(
	{
		storage,
		key: "currentValues",
		whitelist: ["currentRole"]
	},
	(state = initialState, action) => {
		switch (action.type) {

			case actionTypes.SetCurrentRole: {
				const { currentRole } = action.payload;
				return { ...state, currentRole };
			}

			default:
				return state;
		}
	}
);

export const actions = {
	setCurrentRole: currentRole => ({
		type: actionTypes.SetCurrentRole,
		payload: { currentRole }
	}),
};

export function* saga() {
	yield;
}
