import React, { useState, useEffect } from 'react'
import {
	Typography,
	Tabs, 
	Tab,
	Select,
	MenuItem,
	Switch,
	TextField,
	FormControlLabel,
} from "@material-ui/core";
import ChipInput from 'material-ui-chip-input';
import {
	KeyboardDatePicker,
  } from '@material-ui/pickers';
import { Link, Redirect } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import SVG from "react-inlinesvg";

import { useSubheader } from '../../../../../_metronic/layout';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import { 
	get_mentor_library_details, 
	get_mentor_library_content,
	get_mentor_enrollees_list, 
	update_mentor_library_settings, 
	publish_mentor_library,
	sort_mentor_module,
	sort_mentor_lesson,
} from '../../_redux/LibrariesCrud';
import { 
	AddMentorCompanyLibrary, 
	CreateMentorEnrollee, 
	CreateMentorModule, 
	CreateMentorModuleLesson, 
	CreateMentorLibraryLesson, 
	EditMentorModule, 
	EditMentorLesson, 
} from './components/LibraryDetailsModals';
import { CompaniesEnrolledList, MentorEnrolleesList } from './components/LibraryDetailsTables';
import { Spinner } from 'react-bootstrap';

export const MentorLibraryDetails = injectIntl(({ params, intl }) => {
	const [recentLibraryTab, setRecentLibraryTab] = useState("Training Details");
	const [library, setLibrary] = useState({});
	const [libraryContent, setLibraryContent] = useState([]);
	const [enrolleesList, setEnrolleesList] = useState([]);
	const [init, setInit] = useState(false);
	const [accessDenied, setAccessDenied] = useState(false);
	const [companiesEnrolled, setCompaniesEnrolled] = useState([]);
	const [disableSort, setDisableSort] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	useEffect(() => {
		get_mentor_library_details(params.library_party_id).then(({ data: { library_query } }) => {
			if(library_query) {
				setLibrary(library_query);
			}
		}).catch(err => {
			console.log(err);
			setAccessDenied(true);
		});

		get_mentor_library_content(params.library_party_id).then(({ data: { library_content_query } }) => {
			if(library_content_query) {
				setLibraryContent(library_content_query);
			}
			setInit(true);
		}).catch(err => console.log(err))

		get_mentor_enrollees_list(params.library_party_id).then(({ data: { enrollees_list_query, companies_enrolled_query, library_mentors_query } }) => {
			if(enrollees_list_query) {
				setEnrolleesList(enrollees_list_query);
				setCompaniesEnrolled(companies_enrolled_query);
			}
		}).catch(err => console.log(err))
	}, [params.library_party_id]);
	

	const suhbeader = useSubheader();
	suhbeader.setTitle(library.title || "Library");

	if(accessDenied) {
		snackbarAlert("error", "Access Denied");
		return <Redirect to="/" />
	}

	if(!init || !library.title) {
		return <Spinner animation="grow" />
	}

	return <>
		<div className="row">
			<div className="col-12">
				<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
					<div className="card-header">
						<h3 className="card-title font-weight-bolder">{library.title}</h3>
						<div className="card-toolbar">
							<Link
								to={`/libraries/update-library/${params.library_party_id}`}
								className="btn btn-primary mr-3"
							>
								Edit
							</Link>
							{Boolean(library.isPublished) ? <button
								type="button"
								className="btn btn-danger"
								onClick={async () => {
									try {
										const { status, data: { library_query } } = await publish_mentor_library(params.library_party_id, { publish: !library.isPublished });
										if(status === 200) {
											snackbarAlert("warning", "Library Unpublished");
											setLibrary(library_query);
										}
									} catch(err) {
										console.log(err);
										snackbarAlert("error", "Something went wrong");
									}
								}}
							>
								Unpublish
							</button> : <button
								type="button"
								className="btn btn-success"
								onClick={async () => {
									try {
										const { status, data: { library_query } } = await publish_mentor_library(params.library_party_id, { publish: !library.isPublished });
										if(status === 200) {
											snackbarAlert("success", "Library published");
											setLibrary(library_query);
										}
									} catch(err) {
										console.log(err);
										snackbarAlert("error", "Something went wrong");
									}
								}}
							>
								Publish
							</button>}
						</div>
					</div>
					<div className="card-body">
						<div className="d-flex justify-content-center">
							<Tabs
								className="mb-3"
								value={recentLibraryTab}
								onChange={(e, newValue) => {
									setRecentLibraryTab(newValue);
								}}
								indicatorColor="secondary"
								textColor="secondary"
								variant="scrollable"
								// variant="fullWidth"
							>
								<Tab value="Overview" label="Overview" />
								<Tab value="Training Details" label="Training Details" />
								<Tab value="Users" label="Users" />
								<Tab value="Settings" label="Settings" />
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</div>
		{(() => {
			switch(recentLibraryTab) {
				case "Overview": 
					return <div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-header">
									<h3 className="card-title font-weight-bolder">Overview</h3>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-lg-3 col-md-3">
											<img 
												src={library.image_file ? `${process.env.REACT_APP_S3_BUCKET}/library_uploads/images/${library.image_file}` : "https://lernnex.s3.amazonaws.com/library_uploads/images/default_library_image.jpg"}
												alt={library.title}
												className="w-100 rounded"
											/>
										</div>
										<div className="col-lg-9 col-md-3">
											{
												Boolean(library.description) && (
													<div>
														<Typography variant="h6">
															Description: 
														</Typography>

														<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
															{library.description}
														</Typography>
														<br />
													</div>
												)
											}

											{
												Boolean(library.context && library.context.what_will_you_learn) && (
													<div>
														<Typography variant="h6">
															What will you learn?: 
														</Typography>

														<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
															{library.context.what_will_you_learn}
														</Typography>
														<br />
													</div>
												)
											}

											{
												Boolean(library.context && library.context.who_should_take_this) && (
													<div>
														<Typography variant="h6">
															Who should take this?: 
														</Typography>

														<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
															{library.context.who_should_take_this}
														</Typography>
														<br />
													</div>
												)
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				case "Training Details": 
					return <div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-header">
									<h3 className="card-title font-weight-bolder">{library.settings.group_content ? "Modules and Lessons" : "Library Lessons"}</h3>
									<div className="card-toolbar">
										{library.settings.group_content ? 
											<CreateMentorModule 
												library={library} 
												libraryContent={libraryContent} 
												setLibraryContent={setLibraryContent} 
											/> : <CreateMentorLibraryLesson 
												headerButton={true}
												library={library} 
												libraryContent={libraryContent} 
												setLibraryContent={setLibraryContent} 
											/>}
										
									</div>
								</div>

								<div className="card-body">
									
									{(() => {

										if(!libraryContent.length) {
											return <div className="d-flex ml-5 mb-2 border-bottom">
												No content yet...
											</div>
										}
										
										return libraryContent.sort((a,b) => (a.sort-b.sort)).map((indiv_module, module_index) => {
											if(library.settings.group_content) {
												return (
													<div key={module_index} className="form-group">
														<div className="p-3 bg-secondary d-flex rounded mb-2 align-items-center">
															<Typography className="font-weight-bolder">
																{indiv_module.title}
															</Typography>

															<button 
																disabled={disableSort || !module_index}
																type="button"
																style={{ transform: "rotate(180deg)" }}
																className={`ml-auto btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !module_index ? "muted" : "primary"}`}
																onClick={async () => {
																	setDisableSort(true);
																	await sort_mentor_module(indiv_module.resource_id, { 
																		library_party_id: library.party_id,
																		module_index: module_index,
																		sort: module_index - 1
																	}).then(({ data: { library_content_query } }) => {
																		if(library_content_query) {
																			setLibraryContent(library_content_query);
																			snackbarAlert("success", "Module Moved Up");
																		}
																		setDisableSort(false);
																	}).catch(err => {
																		console.log(err)
																		setDisableSort(false);
																		snackbarAlert("error", "Something went wrong!");
																	});
																}}
															>
																<SVG
																	src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																/>
															</button>

															<button 
																disabled={libraryContent.length - 1 === module_index}
																type="button"
																className={`ml-3 btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || libraryContent.length - 1 === module_index ? "muted" : "primary"}`}
																onClick={async () => {
																	setDisableSort(true);
																	await sort_mentor_module(indiv_module.resource_id, { 
																		library_party_id: library.party_id,
																		module_index: module_index,
																		sort: module_index + 1
																	}).then(({ data: { library_content_query } }) => {
																		if(library_content_query) {
																			setLibraryContent(library_content_query);
																			snackbarAlert("success", "Module Moved Down");
																		}
																		setDisableSort(false);
																	}).catch(err => {
																		console.log(err)
																		setDisableSort(false);
																		snackbarAlert("error", "Something went wrong!");
																	});
																}}
															>
																<SVG
																	src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																/>
															</button>

															<span className="mx-3 text-muted"> | </span>

															

															{ indiv_module.resource_id !== "library_lesson" && <>
																<EditMentorModule 
																	library={library} 
																	module={indiv_module}
																	moduleIndex={module_index} 
																	libraryContent={libraryContent} 
																	setLibraryContent={setLibraryContent} 
																/>
																
																<span className="mx-3 text-muted"> | </span>
															</> }

															<CreateMentorModuleLesson 
																library={library} 
																module={indiv_module}
																moduleIndex={module_index} 
																libraryContent={libraryContent} 
																setLibraryContent={setLibraryContent} 
															/>
														</div>
														{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
															return (
																<div key={lesson_index} className="d-flex ml-5 mb-2 border-bottom">
																	<Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary"
																	>
																		{indiv_lesson.title}
																	</Link>

																	<button 
																		disabled={disableSort || !lesson_index}
																		type="button"
																		style={{ transform: "rotate(180deg)" }}
																		className={`ml-auto btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !lesson_index ? "muted" : "primary"}`}
																		onClick={async () => {
																			setDisableSort(true);
																			await sort_mentor_lesson(indiv_lesson.resource_id, { 
																				library_party_id: library.party_id,
																				lesson_index: lesson_index,
																				module_resource_id: indiv_module.resource_id,
																				sort: lesson_index - 1
																			}).then(({ data: { module_query } }) => {
																				if(module_query) {
																					let newLibraryContent = [...libraryContent];
																					newLibraryContent.splice(module_index, 1, module_query)
																					setLibraryContent(newLibraryContent);
																					snackbarAlert("success", "Lesson Moved Up");
																				}
																				setDisableSort(false);
																			}).catch(err => {
																				console.log(err)
																				setDisableSort(false);
																				snackbarAlert("error", "Something went wrong!");
																			});
																		}}
																	>
																		<SVG
																			src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																		/>
																	</button>

																	<button 
																		disabled={disableSort || indiv_module.lessons.length - 1 === lesson_index}
																		type="button"
																		className={`ml-3 btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || indiv_module.lessons.length - 1 === lesson_index ? "muted" : "primary"}`}
																		onClick={async () => {
																			setDisableSort(true);
																			await sort_mentor_lesson(indiv_lesson.resource_id, { 
																				library_party_id: library.party_id,
																				lesson_index: lesson_index,
																				module_resource_id: indiv_module.resource_id,
																				sort: lesson_index + 1
																			}).then(({ data: { module_query } }) => {
																				if(module_query) {
																					let newLibraryContent = [...libraryContent];
																					newLibraryContent.splice(module_index, 1, module_query)
																					setLibraryContent(newLibraryContent);
																					snackbarAlert("success", "Lesson Moved Up");
																				}
																				setDisableSort(false);
																			}).catch(err => {
																				console.log(err)
																				setDisableSort(false);
																				snackbarAlert("error", "Something went wrong!");
																			});
																		}}
																	>
																		<SVG
																			src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")}
																		/>
																	</button>

																	<span className="mx-3 text-muted"> | </span>
																	
																	<EditMentorLesson
																		library={library} 
																		module={indiv_module}
																		moduleIndex={module_index} 
																		libraryContent={libraryContent} 
																		setLibraryContent={setLibraryContent}
																		lesson={indiv_lesson}
																		lessonIndex={lesson_index}
																	/>

																	<span className="mx-3 text-muted"> | </span>

																	<Link 
																		to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																		className="text-primary mr-3"
																	>
																		View
																	</Link>
																	
																</div>
															);
														}) : (
															<div className="text-center">
																Please create a lesson...
															</div>
														)}
													</div>
												)
											} else {
												return <div key={module_index}>
													{indiv_module.lessons && indiv_module.lessons.length ? indiv_module.lessons.map((indiv_lesson, lesson_index) => {
														return (
															<div key={lesson_index} className="d-flex ml-5 mb-2 border-bottom">
																<Link 
																	to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																	className="text-primary"
																>
																	{indiv_lesson.title}
																</Link>

																
																<div className="ml-auto">
																	<EditMentorLesson
																		library={library} 
																		module={indiv_module}
																		moduleIndex={module_index} 
																		libraryContent={libraryContent} 
																		setLibraryContent={setLibraryContent}
																		lesson={indiv_lesson}
																		lessonIndex={lesson_index}
																	/>
																</div>

																<span className="mx-3 text-muted"> | </span>

																<Link 
																	to={`/libraries/${params.library_type}/${params.library_party_id}/module/${indiv_module.resource_id}/lesson/${indiv_lesson.resource_id}`} 
																	className="text-primary mr-3"
																>
																	View
																</Link>
															</div>
														);
													}) : (
														<div className="text-center">
															Please create a lesson...
														</div>
													)}
												</div>
											}
										})
									})()}
								</div>
							</div>
						</div>
					</div>
				case "Users": 
					return <div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-header">
									<h3 className="card-title font-weight-bolder">Enrollees</h3>
									<div className="card-toolbar">
										<CreateMentorEnrollee library={library} enrolleesList={enrolleesList} setEnrolleesList={setEnrolleesList} />
									</div>
								</div>
								<div className="card-body">
									<MentorEnrolleesList enrolleesList={enrolleesList} setEnrolleesList={setEnrolleesList} library={library} />
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-header">
									<h3 className="card-title font-weight-bolder">Companies Enrolled ({companiesEnrolled.length})</h3>
									<div className="card-toolbar">
										<AddMentorCompanyLibrary companiesEnrolled={companiesEnrolled} setCompaniesEnrolled={setCompaniesEnrolled} library={library} />
									</div>
								</div>
								<div className="card-body">
									<CompaniesEnrolledList companiesEnrolled={companiesEnrolled} enrolleesList={enrolleesList} />
								</div>
							</div>
						</div>
					</div>
				case "Settings": 
					return <div className="row">
						<div className="col-12">
							<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
								<div className="card-body">
									<Formik
										enableReinitialize={true}
										initialValues={{
											availability_date: new Date((library.settings.availability_date) ?? new Date()),
											access_type: (library.settings.access_type) ?? "By Approval",
											course_deadline: (library.settings.course_deadline) ?? 30,
											expire_access: Boolean(library.settings.expire_access.isExpiring),
											expire_access_deadline: (library.settings.expire_access.expire_access_deadline) ?? 0,
											hide_outline: Boolean(library.settings.hide_outline),
											allow_questions: Boolean(library.settings.allow_questions.isAllowed),
											email_question_to: (library.settings.allow_questions.email_question_to) ?? [],
											publish_in_olern_cost: (library.settings.publish_in_olern.publish_in_olern_cost) ?? "",
											publish_in_olern_discount: (library.settings.publish_in_olern.publish_in_olern_discount) ?? "",
											course_author_fullname: (library.settings.course_author.fullname) ?? "",
											course_author_email: (library.settings.course_author.email) ?? [],
											payment_link: (library.settings.payment_link) ?? "",
											group_content: (library.settings.group_content) ?? true,
										}}
										validate={values => {
											const errors = {};

											if (!values.availability_date) {
												errors.availability_date = intl.formatMessage({
													id: "AUTH.VALIDATION.REQUIRED_FIELD"
												});
											}

											if (!values.access_type) {
												errors.access_type = intl.formatMessage({
													id: "AUTH.VALIDATION.REQUIRED_FIELD"
												});
											}

											//Course deadline min & max
											if(values.course_deadline > 999) {
												values.course_deadline = 999;
											} else if(values.course_deadline < 1) {
												values.course_deadline = 1;
											}

											if(values.course_deadline.toString().match(/[a-z]/i)) {
												values.course_deadline = 30;
											}

											if(values.publish_in_olern_cost.toString().match(/[a-z]/i)) {
												values.publish_in_olern_cost = "";
											}

											if(values.publish_in_olern_discount.toString().match(/[a-z]/i)) {
												values.publish_in_olern_discount = "";
											}


											//if access expires
											if(values.expire_access && values.expire_access_deadline === "") {
												values.expire_access_deadline = 30;
											} 

											if(!values.expire_access) {
												values.expire_access_deadline = "";
											}

											//access deadline min & max
											if(values.expire_access_deadline > 999) {
												values.expire_access_deadline = 999;
											} else if(values.expire_access_deadline < 1 && values.expire_access) {
												values.expire_access_deadline = 1;
											}

											return errors;
										}}
										onSubmit={async (values, { setSubmitting, resetForm }) => {
											let post_values = {
												"availability_date": values.availability_date.toISOString(),
												"access_type": values.access_type,
												"course_deadline": values.course_deadline,
												"expire_access": {
													"isExpiring": values.expire_access,
													"expire_access_deadline": values.expire_access_deadline
												},
												"hide_outline": values.hide_outline,
												"allow_questions": {
													"isAllowed": values.allow_questions,
													"email_question_to": values.email_question_to
												},
												"publish_in_olern": {
													"publish_in_olern_cost": values.publish_in_olern_cost,
													"publish_in_olern_discount": values.publish_in_olern_discount
												},
												"course_author": {
													"fullname": values.course_author_fullname,
													"email": values.course_author_email
												},
												"payment_link": values.payment_link,
												"group_content": values.group_content,
											};
											
											try {
												const { status, data: { library_query } } = await update_mentor_library_settings(params.library_party_id, post_values);
												
												if(status === 200) {
													snackbarAlert("success", "Settings updated");
													setLibrary(library_query);
												}
											} catch(err) {
												console.log(err);
												snackbarAlert("error", "Something went wrong");
											}
										}}
									>
										{({
											values,
											status,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
											handleReset
										}) => (
											<form
												onSubmit={handleSubmit}
												noValidate
												autoComplete="off"
												className="w-100"
											>
												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Availability Date:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-9">
															<div className="d-flex align-items-center h-100">
																<KeyboardDatePicker
																	color="secondary"
																	disableToolbar
																	variant="inline"
																	autoOk={true}
																	disablePast={true}
																	name={"availability_date"}
																	value={values.availability_date}
																	format="dd/MM/yyyy"
																	helperText={touched.availability_date && errors.availability_date}
																	error={Boolean(touched.availability_date && errors.availability_date)}
																	onChange={date => setFieldValue("availability_date", date)}
																/>
																<Typography>
																	&nbsp; {Boolean(values.availability_date) && values.availability_date.toLocaleDateString(undefined, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' })}
																</Typography>
															</div>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Access Type:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-9">
															<Select
																name="access_type"
																value={values.access_type}
																onChange={handleChange}
																onBlur={handleBlur}
																error={Boolean(
																	touched.access_type && errors.access_type
																)}
																style={{minWidth: 205}}
																color="secondary"
															>
																<MenuItem
																	disabled={true}
																	className="bg-light"
																	value={""}
																>
																	Select Access Type
																</MenuItem>

																<MenuItem className="bg-light" value={"Open"}>
																	Open
																</MenuItem>

																<MenuItem className="bg-light" value={"By Approval"}>
																	By Approval
																</MenuItem>

																<MenuItem className="bg-light" value={"Private"}>
																	Private
																</MenuItem>
															</Select>
															<small className="text-danger">
																{touched.access_type && errors.access_type}
															</small>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex align-items-center h-100">
																<Typography variant="body1">
																	Course Deadline:
																</Typography>
															</div>
														</div>
														<div className="col-9">
															<div className="d-flex align-items-center h-100">
																<div style={{ maxWidth: 50 }}>
																	<TextField
																		inputProps={{
																			style: {
																				textAlign: "center"
																			},
																		}}
																		className="w-100 text-center"
																		name="course_deadline"
																		margin="dense"
																		onBlur={handleBlur}
																		onChange={handleChange}
																		value={values.course_deadline}
																		color="secondary"
																	/>
																</div>
																<Typography>
																	&nbsp; Day/s after enrollment
																</Typography>
															</div>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Expire Access:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-9">
															<div className="d-flex">
																<FormControlLabel
																	control={
																		<Switch
																			onChange={handleChange}
																			checked={Boolean(values.expire_access)}
																			value={values.expire_access}
																			inputProps={{
																				"aria-label": "expire_access",
																				name: "expire_access"
																			}}
																		/>
																	}
																	label={values.expire_access ? "Yes" : ""}
																/>
																<div style={{ maxWidth: 50 }}>
																	<TextField
																		disabled={!values.expire_access}
																		inputProps={{
																			style: {
																				textAlign: "center"
																			},
																		}}
																		color="secondary"
																		className="w-100 text-center"
																		name="expire_access_deadline"
																		margin="dense"
																		onBlur={handleBlur}
																		onChange={handleChange}
																		value={values.expire_access_deadline}
																	/>
																</div>
																<div className="align-self-center">
																	&nbsp; Day/s after enrollment
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Hide Outline To Non-Users:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-9">
															<FormControlLabel
																control={
																	<Switch
																		onChange={handleChange}
																		checked={Boolean(values.hide_outline)}
																		value={values.hide_outline}
																		name="hide_outline"
																	/>
																}
																label={values.hide_outline && "Yes"}
															/>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Allow Questions:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-2">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<FormControlLabel
																		control={
																			<Switch
																				onChange={handleChange}
																				checked={Boolean(values.allow_questions)}
																				value={values.allow_questions}
																				name="allow_questions"
																			/>
																		}
																		label={values.allow_questions && "Yes"}
																	/>
																</div>
															</div>
														</div>
														{
															values.allow_questions && <div className="col-lg-7">
																<ChipInput
																	className="w-100"
																	InputProps={{ color: "secondary", name: "email_question_to", placeholder: "Email questions (Input email and press enter)" }}
																	value={values.email_question_to}
																	onAdd={(chip) => {
																		values.email_question_to.push(chip);
																		setFieldValue("email_question_to", values.email_question_to);
																	}}
																	onDelete={(chip, index) => {
																		values.email_question_to.splice(index, 1);
																		setFieldValue("email_question_to", values.email_question_to);
																	}}
																/>
															</div>
														}
													</div>
												</div>
												

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex align-items-center h-100">
																<Typography variant="body1">
																	Course Price:
																</Typography>
															</div>
														</div>
														<div className="col-3">
															<div className="d-flex h-100 align-items-center">
																<Typography><span>Php. </span></Typography>
																<TextField
																	color="secondary"
																	inputProps={{
																		style: {
																			textAlign: "center",
																			maxWidth: 100,
																		},
																	}}
																	className="text-center"
																	name="publish_in_olern_cost"
																	margin="dense"
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.publish_in_olern_cost}
																/>
																<Typography>&nbsp; per user</Typography>
															</div>
														</div>
														<div className="col-5">
															<div className="d-flex h-100 align-items-center">
																<Typography className="mx-3">
																	Discounted Price:
																</Typography>
																<Typography>Php. </Typography>
																<TextField
																	color="secondary"
																	inputProps={{
																		style: {
																			textAlign: "center",
																			maxWidth: 100,
																		},
																	}}
																	className="text-center"
																	name="publish_in_olern_discount"
																	margin="dense"
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.publish_in_olern_discount}
																/>
																<div className="align-self-center">
																	&nbsp; per user
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Course Author:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-9">
															<div className="border rounded p-2 h-100">
																<div className="form-group">
																	<TextField
																		color="secondary"
																		label="Author Full Name:"
																		placeholder="Input course author full name"
																		InputLabelProps={{
																			shrink: true,
																		}}
																		className="w-50 mr-3"
																		name="course_author_fullname"
																		onBlur={handleBlur}
																		onChange={handleChange}
																		value={values.course_author_fullname}
																	/>
																</div>
														
																<ChipInput
																	className="w-100 mr-3"
																	InputProps={{ name: "course_author_email", placeholder: "Input email and press enter" }}
																	value={values.course_author_email}
																	onAdd={(chip) => {
																		values.course_author_email.push(chip);
																		setFieldValue("course_author_email", values.course_author_email);
																	}}
																	onDelete={(chip, index) => {
																		values.course_author_email.splice(index, 1);
																		setFieldValue("course_author_email", values.course_author_email);
																	}}
																	label="Author Email"
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															</div>
														</div>
													</div>
												</div>

												<div className="form-group">
													<div className="row">
														<div className="col-3">
															<div className="d-flex h-100">
																<div className="align-self-center">
																	<Typography variant="body1">
																		Payment Link:
																	</Typography>
																</div>
															</div>
														</div>
														<div className="col-9">
															<TextField
																color="secondary"
																label="Payment Link"
																placeholder="Input payment link"
																InputLabelProps={{
																	shrink: true,
																}}
																className="w-100 m-0"
																name="payment_link"
																margin="dense"
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.payment_link}
															/>
														</div>
													</div>
												</div>

												<div className="text-right">
													<Link to={`/libraries`}>
														<button
															type="button"
															variant="contained"
															className="btn btn-secondary btn-elevate kt-login__btn-secondary ml-auto mr-2"
														>
															Back to List
														</button>
													</Link>
													<button
														type="submit"
														disabled={isSubmitting}
														className="btn btn-success btn-elevate kt-login__btn-primary"
													>
														Save
													</button>
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>      
						</div>
					</div>
				
				default: 
					return setRecentLibraryTab("Training Details");
			}
		})()}
	</>
})
