import React from 'react';
import { connect } from "react-redux"
import { Redirect } from "react-router-dom";

import {StudentLibrariesList} from "./StudentLibrariesList";
import {MentorLibrariesList} from './MentorLibrariesList';
import {OwnerLibrariesList} from './OwnerLibrariesList';

const LibrariesList = ({ currentRole, match: { params }, ...props }) => {
    switch(currentRole.id) {
        case 1: 
            return <OwnerLibrariesList params={params} />
        case 2: 
            return <StudentLibrariesList params={params} />
        case 3: 
            return <MentorLibrariesList params={params} />

        default:
            return <Redirect to="/" />
    }
}

const mapStateToProps = ({ currentValues: { currentRole } }) => ({ currentRole })

export default connect(mapStateToProps)(LibrariesList);
