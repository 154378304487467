/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";


import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import * as persistCurrentValues from "../../../../../redux/ducks/currentValues.duck";

const AsideMenuList = ({ layoutProps, currentRole, user, setCurrentRole, ...props }) => {
  useEffect(() => {
    if(user && !currentRole) {
      setCurrentRole(user.roles[0]);
    }
  }, [user, setCurrentRole, currentRole]);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  if(!currentRole) {
    return <></>;
  } else if(currentRole.id === 1) {
    return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li className="menu-section ">
            <h4 className="menu-text">Categories</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
  
          <li
            className={`menu-item ${getMenuItemActive("/categories", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/categories">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Color-profile.svg")} />
              </span>
              <span className="menu-text">Categories</span>
            </NavLink>
          </li>
  
          <li className="menu-section ">
            <h4 className="menu-text">Libraries</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
  
          <li
            className={`menu-item ${getMenuItemActive("/libraries/trainings", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/libraries/trainings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")} />
              </span>
              <span className="menu-text">Trainings</span>
            </NavLink>
          </li>
  
          <li
            className={`menu-item ${getMenuItemActive("/libraries/manuals", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/libraries/manuals">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Manuals</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/libraries/policies", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/libraries/policies">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg")} />
              </span>
              <span className="menu-text">Policies</span>
            </NavLink>
          </li>

          <li className="menu-section ">
            <h4 className="menu-text">Companies</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/companies", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/companies/companies-list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
              </span>
              <span className="menu-text">Companies</span>
            </NavLink>
          </li>
        </ul>
      </>
    );
  } else if(currentRole.id === 2) {
    return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
  
          <li className="menu-section ">
            <h4 className="menu-text">Libraries</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
  
          <li
            className={`menu-item ${getMenuItemActive("/libraries", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/libraries/trainings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")} />
              </span>
              <span className="menu-text">All Trainings</span>
            </NavLink>
          </li>

          <li className="menu-section ">
            <h4 className="menu-text">Companies</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/companies", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/companies/companies-list">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
              </span>
              <span className="menu-text">Companies</span>
            </NavLink>
          </li>
        </ul>
      </>
    );
  } else if(currentRole.id === 3) {
    return ( 
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item ${getMenuItemActive("/libraries/trainings", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/libraries/trainings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Display1.svg")} />
              </span>
              <span className="menu-text">Trainings</span>
            </NavLink>
          </li>
        </ul>
      </>
    )
  } else {
    return <></>;
  }

  
}

const mapStateToProps = ({ currentValues: { currentRole }, auth: { user } }) => ({ currentRole, user })


export default connect(mapStateToProps, persistCurrentValues.actions)(AsideMenuList);