import React, { useEffect, useState } from 'react';
import {
	makeStyles, 
	Card,
	CardMedia,
	CardHeader,
	CardContent,
	CardActions,
	Typography,
	InputLabel,
	Select,
	MenuItem,
	FormControl
} from "@material-ui/core";
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import { get_student_libraries_list, request_company_student_library_access } from "../../_redux/LibrariesCrud";

const useStyles = makeStyles({
	libraryCard: {
		minHeight: "50vh",
	}
});


export function StudentLibrariesList({ params }) {
	const [init, setInit] = useState(false);
	const [librariesList, setLibrariesList] = useState([]);
	const [disableRequestButtons, setDisableRequestButtons] = useState(false);
	const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [subCategoryFilter, setSubCategoryFilter] = useState(0);

	const classes = useStyles();

	useEffect(() => {
		get_student_libraries_list().then(({ data: { libraries_query, categories_query } }) => {
			setInit(true)
			if(libraries_query) {
				setLibrariesList(libraries_query);
			}

			if(categories_query) {
                setCategories(categories_query);
            }
		}).catch(err => setInit(true));
	}, []);

	if(!init) {
		return (
			<Spinner animation="grow" />
		)
	}

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>
						<div className="card-header">
							<h3 className="card-title font-weight-bolder">Trainings</h3>
						</div>
						<div className="card-body">

							<div className="form-group d-flex">
                                <FormControl fullWidth>
                                    <InputLabel color="secondary">Category Filter</InputLabel>
                                    <Select
                                        color="secondary"
                                        className="w-100 mb-3"
                                        value={categoryFilter}
                                        onChange={e => {
                                            setCategoryFilter(e.target.value);
                                            if(!e.target.value) {
                                                setSubCategoryFilter(0);
                                                setSubCategories([])
                                            } else {
												setSubCategories(categories.find(indiv_category => indiv_category.party_id === e.target.value).sub_categories);
											}
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            No Filter
                                        </MenuItem>
                                        {categories.map((indiv_category) => {
                                            return <MenuItem key={indiv_category.party_id} value={indiv_category.party_id}>
                                                {indiv_category.name}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl> 
                                

                                {Boolean(categoryFilter) && <FormControl fullWidth className="ml-5">
                                    <InputLabel color="secondary">Sub-Category Filter</InputLabel>
                                    <Select
                                        color="secondary"
                                        className="w-100 mb-3"
                                        value={subCategoryFilter}
                                        onChange={e => {
                                            setSubCategoryFilter(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            No Filter
                                        </MenuItem>
                                        {subCategories.map((indiv_sub_category) => {
                                            return <MenuItem key={indiv_sub_category.party_id} value={indiv_sub_category.party_id}>
                                                {indiv_sub_category.name}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>}
                            </div>
							
							<div className="row">
								{librariesList.map((indiv_library, library_index) => {
									if(categoryFilter && categoryFilter !== indiv_library.categories[0].party_id) return null;

                                    if(subCategoryFilter && subCategoryFilter !== indiv_library.sub_categories[0].party_id) return null;

									return (
										<div key={library_index} className="col-xl-3 col-lg-4 col-md-6">
											<Card className={`${classes.libraryCard} gutter-b`}>
												<CardMedia
													style={{ minHeight: "35vh" }}
													component="img"
													className="p-2 border rounded"
                                                    image={indiv_library.image_file ? `${process.env.REACT_APP_S3_BUCKET}/library_uploads/images/${indiv_library.image_file}` : "https://lernnex.s3.amazonaws.com/library_uploads/images/default_library_image.jpg"}
													title={indiv_library.title}
												/>
												<Link
													to={indiv_library.company_slug ? `/companies/${indiv_library.company_slug}/libraries/${indiv_library.party_id}` : `/libraries/${params.library_type}/${indiv_library.party_id}`}
												>
													<CardHeader style={{ minHeight: "8vh" }} title={indiv_library.title} className="text-center mb-0" />
												</Link>

												{Boolean(indiv_library.price) && <CardHeader 
													className="pt-2 pb-0"
													title={
														<div className="w-75 mx-auto px-4">
															<Typography 
																variant="h6" 
																component="p"
																style={{ backgroundColor: "rgb(255, 255, 107)" }} 
																className="font-weight-bolder text-center rounded py-2"
															>
																Php 
																<span 
																	className="mx-2"
																	style={{ textDecoration: indiv_library.price_discounted ? "line-through" : "" }}
																>
																	{(indiv_library.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
																</span>

																{Boolean(indiv_library.price_discounted) && <span 
																	className="mx-2 text-danger"
																>
																	{(indiv_library.price_discounted).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
																</span>}
															</Typography>
														</div>
													}
												/>}

												<CardContent>
													{Boolean((new Date()) < (new Date(indiv_library.settings.availability_date))) && <Typography>
                                                        <strong>Availablility Date: </strong> {(new Date(indiv_library.settings.availability_date)).toLocaleDateString(undefined, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' })}
                                                    </Typography>}

													<Typography>
                                                        <strong>Category: </strong> {indiv_library.categories[0] && indiv_library.categories[0].name}
                                                    </Typography>

                                                    <Typography>
                                                        <strong>Sub-Category: </strong> {indiv_library.sub_categories[0] && indiv_library.sub_categories[0].name}
                                                    </Typography>

													{Boolean(indiv_library.settings.course_author.fullname) && <Typography>
                                                        <strong>Author: </strong> {indiv_library.settings.course_author.fullname}
                                                    </Typography>}
												</CardContent>

												<CardActions className="d-flex justify-content-center align-items-center pb-4">
													{(() => {

														if(indiv_library.access_granted) {
															return <button type="button" className="btn btn-secondary btn-disabled">
																Access Granted
															</button>
														} else if(indiv_library.settings.payment_link) {
															return <a
																type="button"
																className="btn btn-success"
																href={indiv_library.settings.payment_link}
																target="_blank"
																rel="noopener noreferrer"
															>
																Get Info
															</a>
														} else {
															if(indiv_library.access_pending) {
																return <button type="button" className="btn btn-warning">
																	Access Pending
																</button>
															} else {
																return <button 
																	type="button" 
																	className="btn btn-primary"
																	disabled={disableRequestButtons === library_index}
																	onClick={async () => {
																		setDisableRequestButtons(library_index);
																		try {
																			const { status, data: { library_query } } = await request_company_student_library_access({ library_party_id: indiv_library.party_id, company_slug: indiv_library.company_slug });
																			if(status === 202) {
																				let new_libraries_list = [...librariesList];
																				new_libraries_list.splice(library_index, 1, library_query);
																				setLibrariesList(new_libraries_list);
																			}
																			setDisableRequestButtons(false);
																		} catch(err) {
																			console.log(err);
																			setDisableRequestButtons(false);
																		}
																		
																	}}
																>
																	Request {(new Date()) < (new Date(indiv_library.settings.availability_date)) && " Early "} Access
																</button>
															}
														}
													})()}
													<div>
														<span className={`btn svg-icon svg-icon-xl svg-icon-${indiv_library.liked}`}>
															<SVG
																src={toAbsoluteUrl("/media/svg/icons/General/Heart.svg")}
															/>
														</span>
													</div>
												</CardActions>
											</Card>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
