import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { useSubheader } from "../../../../../../_metronic/layout";
import { OwnerLibraryUpdate } from './OwnerLibraryUpdate'
import { MentorLibraryUpdate } from './MentorLibraryUpdate'

export const LibraryUpdate = ({ currentRole, match: { params } }) => {

    const suhbeader = useSubheader();
	suhbeader.setTitle("Update Library");

    switch(currentRole.id) {
        case 1: 
            return <OwnerLibraryUpdate params={params} />
        case 3: 
            return <MentorLibraryUpdate params={params} />
        default: 
            return <Redirect to="/" />
    }
}

const mapStateToProps = ({ currentValues: { currentRole } }) => ({ currentRole })

export default connect(mapStateToProps)(LibraryUpdate)