import React, { useState } from 'react'
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import SVG from "react-inlinesvg";

import { 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	TextField,
 } from '@material-ui/core';

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import { 
	create_owner_enrollee, 
	create_owner_library_lesson, 
	create_owner_module, 
	create_owner_module_lesson, 
	delete_owner_lesson, 
	delete_owner_module, 
	add_owner_company_library, 
	sort_owner_lesson, 
	sort_owner_module, 
	update_owner_lesson, 
	update_owner_module,
	add_owner_library_mentor,
	create_mentor_enrollee, 
	add_mentor_company_library,
	create_mentor_module,
	create_mentor_module_lesson,
	create_mentor_library_lesson,
	update_mentor_module,
	update_mentor_lesson,
	sort_mentor_module, 
	sort_mentor_lesson, 
} from '../../../_redux/LibrariesCrud';

export const CreateOwnerModule = injectIntl(({ library, libraryContent, setLibraryContent, intl }) => {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: "",
			library_party_id: library.party_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await create_owner_module(values).then(({ data: { new_module } }) => {
				if(new_module) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent.push(new_module);
					setLibraryContent(newLibraryContent);
					resetForm();
					handleClose();
					window.scrollTo(0,document.body.scrollHeight);
				}
			}).catch(err => console.log(err)).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Add group
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new module</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateOwnerModuleLesson = injectIntl(({ library, libraryContent, setLibraryContent, module, moduleIndex, intl }) => {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: "",
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			if(values.module_resource_id === "library_lesson") {
				await create_owner_library_lesson(values).then(({ data: { new_lesson } }) => {
					if(new_lesson) {
						let newLibraryContent = [...libraryContent];
						newLibraryContent[moduleIndex].lessons.push(new_lesson);
						setLibraryContent(newLibraryContent);
						resetForm();
						handleClose();
						setSubmitting(false);
					}
				}).catch(err => {
					console.log(err)
					setSubmitting(false);
				});
			} else {
				await create_owner_module_lesson(values).then(({ data: { new_lesson } }) => {
					if(new_lesson) {
						let newLibraryContent = [...libraryContent];
						newLibraryContent[moduleIndex].lessons.push(new_lesson);
						setLibraryContent(newLibraryContent);
						resetForm();
						handleClose();
						setSubmitting(false);
					}
				}).catch(err => {
					console.log(err)
					setSubmitting(false);
				});
			}
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button type="button" className="btn p-0 text-primary" onClick={handleClickOpen}>
					Add Topic
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Module: </span>{module.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateOwnerEnrollee = injectIntl(({ library, enrolleesList, setEnrolleesList, intl }) => {
	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			email: "",
			library_party_id: library.party_id
		}}
		validationSchema={Yup.object().shape({
			email: Yup.string()
				.email("Wrong email format")
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
		})}
		onSubmit={async (values, { resetForm }) => {
			setSubmitting(true);
			create_owner_enrollee(values).then(({ data: { new_enrollee } }) => {
				if(new_enrollee) {
					let new_enrollees_list = [...enrolleesList];
					new_enrollees_list.push(new_enrollee);
					setEnrolleesList(new_enrollees_list);
					resetForm();
					setOpen(false);
					setSubmitting(false);
				}
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Enroll a Student
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Enroll a Person</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Email Address<span className="text-danger">*</span></span>}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                            error={Boolean(touched.email && errors.email)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={submitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const AddOwnerCompanyLibrary = injectIntl(({ library, intl, companiesEnrolled, setCompaniesEnrolled }) => {
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			slug: "",
			library_party_id: library.party_id
		}}
		validationSchema={Yup.object().shape({
			slug: Yup.string()
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
		})}
		onSubmit={(values, { resetForm, setSubmitting }) => {
			add_owner_company_library(values).then(({ data: { company_query } }) => {
				if(company_query) {
					let new_company_enrolled = [...companiesEnrolled];
					new_company_enrolled.push(company_query);
					setCompaniesEnrolled(new_company_enrolled);
					resetForm();
					handleClose();
					snackbarAlert("success", "Company Students Added");
				}
			}).catch(err => {
				setSubmitting(false);
				snackbarAlert("error", "Something went wrong");
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Add Company Students
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Give company student access.</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Company Domain<span className="text-danger">*</span></span>}
                            name="slug"
                            onBlur={handleBlur}
                            onChange={handleChange}
							placeholder="Insert company domain (e.g. mobile-optima-inc)"
                            value={values.slug}
                            helperText={touched.slug && errors.slug}
							InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.slug && errors.slug)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateOwnerLibraryLesson = injectIntl(({ library, libraryContent, setLibraryContent, intl }) => {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: "",
			library_party_id: library.party_id,
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await create_owner_library_lesson(values).then(({ data: { new_lesson } }) => {
				if(new_lesson) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[0].lessons.push(new_lesson);
					setLibraryContent(newLibraryContent);
					resetForm();
					handleClose();
					setSubmitting(false);
				}
			}).catch(err => {
				console.log(err)
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button type="button" className="btn btn-primary" onClick={handleClickOpen}>
					Add Topic
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const EditOwnerModule = injectIntl(({ library, libraryContent, setLibraryContent, intl, module, moduleIndex }) => {
	const [open, setOpen] = useState(false);
	const [disableSort, setDisableSort] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};


	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: module.title,
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await update_owner_module(values.module_resource_id, values).then(({ data: { module_query } }) => {
				if(module_query) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[moduleIndex].title = module_query.title;
					setLibraryContent(newLibraryContent);
					handleClose();
				}
			}).catch(err => console.log(err)).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn p-0 text-primary"
					onClick={handleClickOpen}
				>
					Edit
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title" className="text-center">Update Module</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Module: </span>{module.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
						<div className="mr-auto d-flex align-items-center">
							<button 
								disabled={disableSort || !moduleIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_owner_module(module.resource_id, { 
										library_party_id: library.party_id,
										module_index: moduleIndex,
										sort: moduleIndex - 1
									}).then(({ data: { library_content_query } }) => {
										if(library_content_query) {
											setLibraryContent(library_content_query);
											snackbarAlert("success", "Module Moved Up");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !moduleIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-up.svg")}
									/>
								</span>
								<br />
								<span>Move Up</span>
							</button>

							<button 
								disabled={disableSort || libraryContent.length - 1 === moduleIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_owner_module(module.resource_id, { 
										library_party_id: library.party_id,
										module_index: moduleIndex,
										sort: moduleIndex + 1
									}).then(({ data: { library_content_query } }) => {
										if(library_content_query) {
											setLibraryContent(library_content_query);
											snackbarAlert("success", "Module Moved Down");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || libraryContent.length - 1 === moduleIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-down.svg")}
									/>
								</span>
								<br />
								<span>Move Down</span>
							</button>
						</div>
						<div>
							<Button onClick={handleClose} className="text-danger">
								Cancel
							</Button>
							<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
								Update
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const DeleteOwnerModule = injectIntl(({ library, libraryContent, setLibraryContent, intl, module, moduleIndex }) => {
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};


	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.module_resource_id) {
				errors.module_resource_id = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await delete_owner_module(values.module_resource_id, values).then(({ status }) => {
				if(status === 200) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent.splice(moduleIndex, 1);
					setLibraryContent(newLibraryContent);
					snackbarAlert("warning", "Module Deleted!");
					handleClose();
				}
			}).catch(err => {
				console.log(err);
				snackbarAlert("error", "Something went wrong!");
			}).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn p-0 text-danger"
					onClick={handleClickOpen}
				>
					Delete
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
					<DialogTitle id="form-dialog-title" className="text-center">Delete Module</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder text-center d-block">{module.title}</span>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className="text-danger">
							Cancel
						</Button>
						<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const DeleteOwnerLesson = injectIntl(({ library, libraryContent, setLibraryContent, intl, module, moduleIndex, lesson, lessonIndex }) => {
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};


	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await delete_owner_lesson(lesson.resource_id, values).then(({ status }) => {
				if(status === 200) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[moduleIndex].lessons.splice(lessonIndex, 1);
					setLibraryContent(newLibraryContent);
					handleClose();
				}
			}).catch(err => {
				console.log(err);
				snackbarAlert("error", "Something went wrong!");
			}).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn p-0 text-danger"
					onClick={handleClickOpen}
				>
					Delete
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
					<DialogTitle id="form-dialog-title" className="text-center">Delete Lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder text-center d-block">{lesson.title}</span>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className="text-danger">
							Cancel
						</Button>
						<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const EditOwnerLesson = injectIntl(({ library, libraryContent, setLibraryContent, intl, module, moduleIndex, lesson, lessonIndex }) => {
	const [open, setOpen] = useState(false);
	const [disableSort, setDisableSort] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};


	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: lesson.title,
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await update_owner_lesson(lesson.resource_id, values).then(({ data: { lesson_query } }) => {
				if(lesson_query) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[moduleIndex].lessons[lessonIndex].title = lesson_query.title;
					setLibraryContent(newLibraryContent);
					handleClose();
					snackbarAlert("success", "Lesson Updated");
				}
			}).catch(err => {
				console.log(err);
				snackbarAlert("error", "Something went wrong!");
			}).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn p-0 text-primary"
					onClick={handleClickOpen}
				>
					Edit
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title" className="text-center">Update Lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder">Target Lesson: </span>{lesson.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
						<div className="mr-auto d-flex align-items-center">
							<button 
								disabled={disableSort || !lessonIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_owner_lesson(lesson.resource_id, { 
										library_party_id: library.party_id,
										lesson_index: lessonIndex,
										module_resource_id: module.resource_id,
										sort: lessonIndex - 1
									}).then(({ data: { module_query } }) => {
										if(module_query) {
											let newLibraryContent = [...libraryContent];
											newLibraryContent.splice(moduleIndex, 1, module_query)
											setLibraryContent(newLibraryContent);
											snackbarAlert("success", "Lesson Moved Up");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !lessonIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-up.svg")}
									/>
								</span>
								<br />
								<span>Move Up</span>
							</button>

							<button 
								disabled={disableSort || module.lessons.length - 1 === lessonIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_owner_lesson(lesson.resource_id, { 
										library_party_id: library.party_id,
										lesson_index: lessonIndex,
										module_resource_id: module.resource_id,
										sort: lessonIndex + 1
									}).then(({ data: { module_query } }) => {
										if(module_query) {
											let newLibraryContent = [...libraryContent];
											newLibraryContent.splice(moduleIndex, 1, module_query)
											setLibraryContent(newLibraryContent);
											snackbarAlert("success", "Lesson Moved Down");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || module.lessons.length - 1 === lessonIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-down.svg")}
									/>
								</span>
								<br />
								<span>Move Down</span>
							</button>
						</div>
						<div>
							<Button onClick={handleClose} className="text-danger">
								Cancel
							</Button>
							<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
								Update
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const AddLibraryMentor = injectIntl(({ library, intl, mentorsList, setMentorsList }) => {
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}
	

	return <Formik
		enableReinitialize
		initialValues={{
			email: "",
			library_party_id: library.party_id
		}}
		validationSchema={Yup.object().shape({
			email: Yup.string()
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
		})}
		onSubmit={(values, { resetForm, setSubmitting }) => {
			setSubmitting(true);
			add_owner_library_mentor(values).then(({ data: { person } }) => {
				if(person) {
					let new_mentors_list = [...mentorsList];
					new_mentors_list.push(person);
					setMentorsList(new_mentors_list);
					resetForm();
					setOpen(false);
					setSubmitting(false);
				}
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
				snackbarAlert("error", err.response.data.message);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Add Library Mentor
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Give mentor access.</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Email Address<span className="text-danger">*</span></span>}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
							placeholder="Email Address"
                            value={values.email}
                            helperText={touched.email && errors.email}
							InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.email && errors.email)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateMentorEnrollee = injectIntl(({ library, enrolleesList, setEnrolleesList, intl }) => {
	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			email: "",
			library_party_id: library.party_id
		}}
		validationSchema={Yup.object().shape({
			email: Yup.string()
				.email("Wrong email format")
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
		})}
		onSubmit={async (values, { resetForm }) => {
			setSubmitting(true);
			create_mentor_enrollee(values).then(({ data: { new_enrollee } }) => {
				if(new_enrollee) {
					let new_enrollees_list = [...enrolleesList];
					new_enrollees_list.push(new_enrollee);
					setEnrolleesList(new_enrollees_list);
					resetForm();
					setOpen(false);
					setSubmitting(false);
				}
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Enroll a Student
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Enroll a Person</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Email Address<span className="text-danger">*</span></span>}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                            error={Boolean(touched.email && errors.email)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={submitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const AddMentorCompanyLibrary = injectIntl(({ library, intl, companiesEnrolled, setCompaniesEnrolled }) => {
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			slug: "",
			library_party_id: library.party_id
		}}
		validationSchema={Yup.object().shape({
			slug: Yup.string()
				.min(3, "Minimum 3 symbols")
				.max(50, "Maximum 50 symbols")
				.required(
					intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
		})}
		onSubmit={(values, { resetForm, setSubmitting }) => {
			add_mentor_company_library(values).then(({ data: { company_query } }) => {
				if(company_query) {
					let new_company_enrolled = [...companiesEnrolled];
					new_company_enrolled.push(company_query);
					setCompaniesEnrolled(new_company_enrolled);
					resetForm();
					handleClose();
					snackbarAlert("success", "Company Students Added");
				}
			}).catch(err => {
				setSubmitting(false);
				snackbarAlert("error", "Something went wrong");
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Add Company Students
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Give company student access.</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Company Domain<span className="text-danger">*</span></span>}
                            name="slug"
                            onBlur={handleBlur}
                            onChange={handleChange}
							placeholder="Insert company domain (e.g. mobile-optima-inc)"
                            value={values.slug}
                            helperText={touched.slug && errors.slug}
							InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.slug && errors.slug)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateMentorModule = injectIntl(({ library, libraryContent, setLibraryContent, intl }) => {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: "",
			library_party_id: library.party_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await create_mentor_module(values).then(({ data: { new_module } }) => {
				if(new_module) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent.push(new_module);
					setLibraryContent(newLibraryContent);
					resetForm();
					handleClose();
					window.scrollTo(0,document.body.scrollHeight);
				}
			}).catch(err => console.log(err)).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleClickOpen}
				>
					Add group
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new module</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateMentorModuleLesson = injectIntl(({ library, libraryContent, setLibraryContent, module, moduleIndex, intl }) => {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: "",
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			if(values.module_resource_id === "library_lesson") {
				await create_mentor_library_lesson(values).then(({ data: { new_lesson } }) => {
					if(new_lesson) {
						let newLibraryContent = [...libraryContent];
						newLibraryContent[moduleIndex].lessons.push(new_lesson);
						setLibraryContent(newLibraryContent);
						resetForm();
						handleClose();
						setSubmitting(false);
					}
				}).catch(err => {
					console.log(err)
					setSubmitting(false);
				});
			} else {
				await create_mentor_module_lesson(values).then(({ data: { new_lesson } }) => {
					if(new_lesson) {
						let newLibraryContent = [...libraryContent];
						newLibraryContent[moduleIndex].lessons.push(new_lesson);
						setLibraryContent(newLibraryContent);
						resetForm();
						handleClose();
						setSubmitting(false);
					}
				}).catch(err => {
					console.log(err)
					setSubmitting(false);
				});
			}
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button type="button" className="btn p-0 text-primary" onClick={handleClickOpen}>
					Add Topic
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Module: </span>{module.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const CreateMentorLibraryLesson = injectIntl(({ library, libraryContent, setLibraryContent, intl }) => {
	const [open, setOpen] = useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: "",
			library_party_id: library.party_id,
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await create_mentor_library_lesson(values).then(({ data: { new_lesson } }) => {
				if(new_lesson) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[0].lessons.push(new_lesson);
					setLibraryContent(newLibraryContent);
					resetForm();
					handleClose();
					setSubmitting(false);
				}
			}).catch(err => {
				console.log(err)
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button type="button" className="btn btn-primary" onClick={handleClickOpen}>
					Add Topic
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title">Create new lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Library: </span>{library.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} className="text-danger">
						Cancel
					</Button>
					<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
						Submit
					</Button>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const EditMentorModule = injectIntl(({ library, libraryContent, setLibraryContent, intl, module, moduleIndex }) => {
	const [open, setOpen] = useState(false);
	const [disableSort, setDisableSort] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};


	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: module.title,
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await update_mentor_module(values.module_resource_id, values).then(({ data: { module_query } }) => {
				if(module_query) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[moduleIndex].title = module_query.title;
					setLibraryContent(newLibraryContent);
					handleClose();
				}
			}).catch(err => console.log(err)).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn p-0 text-primary"
					onClick={handleClickOpen}
				>
					Edit
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title" className="text-center">Update Module</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bold">Target Module: </span>{module.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
						<div className="mr-auto d-flex align-items-center">
							<button 
								disabled={disableSort || !moduleIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_mentor_module(module.resource_id, { 
										library_party_id: library.party_id,
										module_index: moduleIndex,
										sort: moduleIndex - 1
									}).then(({ data: { library_content_query } }) => {
										if(library_content_query) {
											setLibraryContent(library_content_query);
											snackbarAlert("success", "Module Moved Up");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !moduleIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-up.svg")}
									/>
								</span>
								<br />
								<span>Move Up</span>
							</button>

							<button 
								disabled={disableSort || libraryContent.length - 1 === moduleIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_mentor_module(module.resource_id, { 
										library_party_id: library.party_id,
										module_index: moduleIndex,
										sort: moduleIndex + 1
									}).then(({ data: { library_content_query } }) => {
										if(library_content_query) {
											setLibraryContent(library_content_query);
											snackbarAlert("success", "Module Moved Down");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || libraryContent.length - 1 === moduleIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-down.svg")}
									/>
								</span>
								<br />
								<span>Move Down</span>
							</button>
						</div>
						<div>
							<Button onClick={handleClose} className="text-danger">
								Cancel
							</Button>
							<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
								Update
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})

export const EditMentorLesson = injectIntl(({ library, libraryContent, setLibraryContent, intl, module, moduleIndex, lesson, lessonIndex }) => {
	const [open, setOpen] = useState(false);
	const [disableSort, setDisableSort] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const snackbarAlert = (variant, message) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, { variant });
	};


	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return <Formik
		enableReinitialize
		initialValues={{
			title: lesson.title,
			library_party_id: library.party_id,
			module_resource_id: module.resource_id
		}}
		validate={values => {
			const errors = {};

			if (!values.title) {
				errors.title = intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD"
				});
			}

			return errors;
		}}
		onSubmit={async (values, { setSubmitting, resetForm }) => {
			await update_mentor_lesson(lesson.resource_id, values).then(({ data: { lesson_query } }) => {
				if(lesson_query) {
					let newLibraryContent = [...libraryContent];
					newLibraryContent[moduleIndex].lessons[lessonIndex].title = lesson_query.title;
					setLibraryContent(newLibraryContent);
					handleClose();
					snackbarAlert("success", "Lesson Updated");
				}
			}).catch(err => {
				console.log(err);
				snackbarAlert("error", "Something went wrong!");
			}).finally(() => setSubmitting(false));
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<button
					type="button"
					className="btn p-0 text-primary"
					onClick={handleClickOpen}
				>
					Edit
				</button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"md"}>
					<DialogTitle id="form-dialog-title" className="text-center">Update Lesson</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<span className="font-weight-bolder">Target Lesson: </span>{lesson.title}
						</DialogContentText>
						<TextField
							autoFocus
							margin="normal"
                            color="secondary"
                            label={<span>Title<span className="text-danger">*</span></span>}
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            helperText={touched.title && errors.title}
                            error={Boolean(touched.title && errors.title)}
						/>
					</DialogContent>
					<DialogActions>
						<div className="mr-auto d-flex align-items-center">
							<button 
								disabled={disableSort || !lessonIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_mentor_lesson(lesson.resource_id, { 
										library_party_id: library.party_id,
										lesson_index: lessonIndex,
										module_resource_id: module.resource_id,
										sort: lessonIndex - 1
									}).then(({ data: { module_query } }) => {
										if(module_query) {
											let newLibraryContent = [...libraryContent];
											newLibraryContent.splice(moduleIndex, 1, module_query)
											setLibraryContent(newLibraryContent);
											snackbarAlert("success", "Lesson Moved Up");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || !lessonIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-up.svg")}
									/>
								</span>
								<br />
								<span>Move Up</span>
							</button>

							<button 
								disabled={disableSort || module.lessons.length - 1 === lessonIndex}
								className="btn p-0 mx-3 text-center"
								onClick={async () => {
									setDisableSort(true);
									await sort_mentor_lesson(lesson.resource_id, { 
										library_party_id: library.party_id,
										lesson_index: lessonIndex,
										module_resource_id: module.resource_id,
										sort: lessonIndex + 1
									}).then(({ data: { module_query } }) => {
										if(module_query) {
											let newLibraryContent = [...libraryContent];
											newLibraryContent.splice(moduleIndex, 1, module_query)
											setLibraryContent(newLibraryContent);
											snackbarAlert("success", "Lesson Moved Down");
										}
										setDisableSort(false);
										handleClose();
									}).catch(err => {
										console.log(err)
										setDisableSort(false);
										snackbarAlert("error", "Something went wrong!");
									});
								}}
							>
								<span className={`btn p-0 svg-icon svg-icon-xl svg-icon-${disableSort || module.lessons.length - 1 === lessonIndex ? "muted" : "primary"}`}>
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-down.svg")}
									/>
								</span>
								<br />
								<span>Move Down</span>
							</button>
						</div>
						<div>
							<Button onClick={handleClose} className="text-danger">
								Cancel
							</Button>
							<Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
								Update
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</form>
		)}
	</Formik>
})
