import React, { useState } from 'react'
import { Formik, FieldArray } from 'formik'
import { injectIntl } from 'react-intl'
import Vimeo from '@u-wave/react-vimeo';
import * as yup from "yup";

import { 
	FormControlLabel, 
	Radio, 
	RadioGroup, 
	TextField, 
	Typography, 
	// Switch 
} from '@material-ui/core'


import { create_owner_element_attachment } from '../../../_redux/LibrariesCrud';
import { DoughnutChart } from '../../../../../widgets/charts';
import { ShowResponses } from './LessonContentModals';


// Start of Image Componenent
export const ElementImageCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	const [imageTempURL, setImageTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null
	  }}
	  validate={values => {
		  const errors = {};

		  if (!values.file) {
			  errors.file = intl.formatMessage({
				  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			  });
		  }

		  return errors;
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
			if(new_element_attachment) {
				let newElements = [...elements];
				newElements[element_index].attachments.push(new_element_attachment);
				setElements([]);
				setElements(newElements);
				window.scrollTo(0,document.body.scrollHeight);
			}
			setSubmitting(false);
		}).catch(err => {
			console.log(err);
			setSubmitting(false);
		});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="image_input"
				type="file"
				name="image_input"
				accept="image/*"
				className="d-none"
				onChange={imageBLOB => {
					let reader = new FileReader();

					if (imageBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							imageBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							imageBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setImageTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<label htmlFor="image_input" className="btn">
				{imageTempURL !== "" ? (
					<img
						alt="upload"
						className="w-100 d-block mx-auto rounded border p-2"
						src={imageTempURL}
						onBlur={handleBlur}
					/>
				) : (
					<div>
						<img
							alt="upload"
							className="w-100 d-block rounded border p-2"
							src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/image_icon.png"
							style={{ maxHeight: "20vh" }}
						/>
						<Typography>
						  Attach
						</Typography>
						{Boolean(touched.file && errors.file) && <Typography align="center" className="text-danger">
						  Image upload is required
						</Typography>}
					</div>
				)}
			</label>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
	  )}
  </Formik>
})

export const ElementImageView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<img 
			src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} 
			alt="Element" 
			className="d-block rounded mx-auto w-50"
		/>
	</>
}
// End of Image Component


// Start of Audio Component
export const ElementAudioCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	const [audioTempURL, setAudioTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null
	  }}
	  validate={values => {
		  const errors = {};

		  if (!values.file) {
			  errors.file = intl.formatMessage({
				  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			  });
		  }

		  return errors;
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="audio_input"
				type="file"
				name="audio_input"
				accept="audio/*"
				className="d-none"
				onChange={audioBLOB => {
					let reader = new FileReader();

					if (audioBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							audioBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							audioBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setAudioTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<label htmlFor="audio_input" className="btn">
				{audioTempURL !== "" ? (
					 <div className="text-center">
						<a
							href={window.URL.createObjectURL(values.file)}
							download={values.file.name}
							className="btn p-0"
						>
							<span>{values.file.name}</span>
							<br />
							<span>Check!</span>
							
						</a>

						<label htmlFor="audio_input" className="text-center w-100 m-0 p-0 btn">
							<img
								width="50"
								src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/audio_icon.png"
								alt="audio"
								className="d-block mx-auto"
								style={{ maxHeight: "20vh" }}
							/>
							<span>Change!</span>
						</label>
					</div>
				) : (
					<div>
						<img
							alt="upload"
							className="w-100 d-block rounded border p-2"
							src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/audio_icon.png"
							style={{ maxHeight: "20vh" }}
						/>
						<Typography>
						  Attach
						</Typography>
						{Boolean(touched.file && errors.file) && <Typography align="center" className="text-danger">
						  Audio upload is required
						</Typography>}
					</div>
				)}
			</label>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
	  )}
  </Formik>
})

export const ElementAudioView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<div>
			<audio controls="controls" className="w-75 d-block mx-auto">
				<source src={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name} />
				Your browser does not support the audio element.
			</audio>
		</div>
	</>
}
// End of Audio Component

// Start of Document Component
export const ElementDocumentCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	const [documentTempURL, setDocumentTempURL] = useState("");

  return <Formik
	  initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null
	  }}
	  validate={values => {
		  const errors = {};

		  if (!values.file) {
			  errors.file = intl.formatMessage({
				  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			  });
		  }

		  return errors;
	  }}
	  onSubmit={(values, { setSubmitting, resetForm }) => {
		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
	  }}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >
			<input
				id="document_input"
				type="file"
				name="document_input"
				accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
				className="d-none"
				onChange={documentBLOB => {
					let reader = new FileReader();

					if (documentBLOB.currentTarget.files[0]) {
						setFieldValue(
							"file",
							documentBLOB.currentTarget.files[0]
						);
						reader.readAsDataURL(
							documentBLOB.currentTarget.files[0]
						);
						reader.onload = image => {
							setDocumentTempURL(image.target.result);
						};
					}
				}}
			/>

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<label htmlFor="document_input" className="btn">
				{documentTempURL !== "" ? (
					<div className="text-center">
						<a
							href={window.URL.createObjectURL(values.file)}
							download={values.file.name}
							className="btn p-0"
						>
							<span>{values.file.name}</span>
							<br />
							<span>Check!</span>
							
						</a>

						<label htmlFor="document_input" className="text-center w-100 m-0 p-0 btn">
							<img
								width="50"
								src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
								alt="document"
								className="d-block mx-auto"
								style={{ maxHeight: "20vh" }}
							/>
							<span>Change!</span>
						</label>
					</div>
				) : (
					<div>
						<img
							alt="upload"
							className="w-100 d-block rounded border p-2"
							src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
							style={{ maxHeight: "20vh" }}
						/>
						<Typography>
						  Attach
						</Typography>
						{Boolean(touched.file && errors.file) && <Typography align="center" className="text-danger">
						  Document upload is required
						</Typography>}
					</div>
				)}
			</label>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
	  )}
  </Formik>
})

export const ElementDocumentView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}
		<div>
			<div className="text-center">
				<label htmlFor="document_input" className="text-center w-100 m-0 p-0 btn">
					<img
						width="50"
						src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png"
						alt="document"
						className="d-block mx-auto"
						style={{ maxHeight: "20vh" }}
					/>
				</label>
				<a
					href={process.env.REACT_APP_S3_BUCKET + attachment.directory + attachment.file_name}
					download={attachment.file_name}
					className="btn p-0"
				>
					<span className="text-primary">{attachment.file_name}</span>
					<br />
					<span>Click to download!</span>
				</a>
			</div>
		</div>
	</>
}
// End of Document Component

// Start of Video Component
export const ElementVideoCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
  return <Formik
	initialValues={{
		library_party_id: params.library_party_id,
		module_resource_id: params.module_resource_id,
		lesson_resource_id: params.lesson_resource_id,
		element_type_id: element.element_type_id,
		element_id: element.id,
		file: null,
		video_link: ""
	}}
	validate={values => {
		const errors = {};

		if (!values.video_link) {
			errors.video_link = intl.formatMessage({
				id: "AUTH.VALIDATION.REQUIRED_FIELD"
			});
		} 
		else if(Boolean(
			!values.video_link.includes("https://vimeo.com/")
		)) {
			errors.video_link = "Invalid video link"
		}
		
		//  else if(
		// 	Boolean((values.video_link ?? "").includes("https://www.youtube.com")) || 
		// 	Boolean((values.video_link ?? "").includes("https://youtu.be/"))
		// ) {
		// 	errors.video_link = "Invalid video link"
		
		// }

		return errors;
	}}
	onSubmit={(values, { setSubmitting, resetForm }) => {
		create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
			if(new_element_attachment) {
				let newElements = [...elements];
				newElements[element_index].attachments.push(new_element_attachment);
				setElements([]);
				setElements(newElements);
				window.scrollTo(0,document.body.scrollHeight);
			}
			setSubmitting(false);
		}).catch(err => {
			console.log(err);
			setSubmitting(false);
		});
	}}
  >
	  {({
		  values,
		  errors,
		  touched,
		  handleChange,
		  handleBlur,
		  handleSubmit,
		  isSubmitting,
		  setFieldValue,
		  handleReset
	  }) => (
		  <form
			  onSubmit={handleSubmit}
			  noValidate
			  autoComplete="off"
			  className="text-center"
		  >

			<div className="form-group">
				<TextField
					label="Vimeo Link"
					color="secondary"
					name="video_link"
					placeholder="Input you vimeo link here..."
					margin="normal"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.video_link}
					helperText={touched.video_link && errors.video_link}
					error={Boolean(touched.video_link && errors.video_link)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			{(() => {
				if(Boolean((values.video_link ?? "").includes("https://player.vimeo.com/video/") || 
 					(values.video_link ?? "").includes("https://vimeo.com/"))) {
					return (
						<div>
							<Vimeo
								style={{ borderRadius: 20, overflow: "hidden" }}
								className="rounded w-75 mx-auto"
								video={values.video_link}
								showTitle
								responsive={true}
							/>
						</div>
					);

 			 	} else if(Boolean((values.video_link ?? "").includes("https://www.youtube.com")) || 
 			 		Boolean((values.video_link ?? "").includes("https://youtu.be/"))) {
					return (
						<div className="w-75 mx-auto">
							{/* <Youtube
								className="rounded w-100 mx-auto"
							  video={
							  	(
							  		values.video_link
								  	.replace("https://www.youtube.com/embed/", "")
								  	.replace("https://youtu.be/", "")
								  	.replace("https://www.youtube.com/watch?v=", "")
								  ).slice(0, 11)
							  }
							  showInfo
							  height={400}
							  responsive={true}
							/> */}

							Youtube is coming soon...
						</div>
					);
 				
 			 	}
			})()}

			<div className="form-group">
				<TextField
					label="Description"
					color="secondary"
					multiline
					name="description"
					placeholder="Description is not required and could be multiple lines..."
					margin="normal"
					variant="outlined"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.description}
					helperText={touched.description && errors.description}
					error={Boolean(touched.description && errors.description)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>

			<button
				type="submit"
				disabled={isSubmitting}
				className="btn btn-success d-block mx-auto"
			>
				Submit
			</button>
		</form>
	  )}
  </Formik>
})

export const ElementVideoView = ({ attachment }) => {
	return <>
		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 

				<hr />

			</div>
		)}

		{(() => {
			if(Boolean((attachment.context.video_link ?? "").includes("https://player.vimeo.com/video/") || 
				(attachment.context.video_link ?? "").includes("https://vimeo.com/"))) {
				return (
					<div>
						<Vimeo
							style={{ borderRadius: 20, overflow: "hidden" }}
							className="rounded w-75 mx-auto"
							video={attachment.context.video_link}
							showTitle
							responsive={true}
						/>
					</div>
				);

			} else if(Boolean((attachment.context.video_link ?? "").includes("https://www.youtube.com")) || 
				Boolean((attachment.context.video_link ?? "").includes("https://youtu.be/"))) {
				return (
					<div className="w-75 mx-auto">
						{/* <Youtube
							className="rounded w-100 mx-auto"
							video={
							(
								attachment.context.video_link
								.replace("https://www.youtube.com/embed/", "")
								.replace("https://youtu.be/", "")
								.replace("https://www.youtube.com/watch?v=", "")
								).slice(0, 11)
							}
							showInfo
							height={400}
							responsive={true}
						/> */}

						Youtube is coming soon...
					</div>
				);
			
			}
		})()}
	</>
}
// End of Video Component

// Start of Text Component
export const ElementTextCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	return <Formik
		initialValues={{
		  library_party_id: params.library_party_id,
		  module_resource_id: params.module_resource_id,
		  lesson_resource_id: params.lesson_resource_id,
		  element_type_id: element.element_type_id,
		  element_id: element.id,
		  file: null,
		  text_content: "",
		}}
			validate={values => {
		  const errors = {};
  
		  if (!values.text_content) {
			  errors.text_content = intl.formatMessage({
				  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			  });
		  } 
  
			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
			  <div className="form-group">
				  <TextField
					  label="Plain Multiline Text"
					  color="secondary"
					  multiline
					  InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
					  name="text_content"
					  placeholder="Description is not required and could be multiple lines..."
					  margin="normal"
					  variant="outlined"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.text_content}
					  helperText={touched.text_content && errors.text_content}
					  error={Boolean(touched.text_content && errors.text_content)}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			  <button
				  type="submit"
				  disabled={isSubmitting}
				  className="btn btn-success d-block mx-auto"
			  >
				  Submit
			  </button>
		  </form>
		)}
	</Formik>
})

export const ElementTextView = ({ attachment }) => {
	return <>
		{Boolean(attachment.context.text_content) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.context.text_content}
				</Typography> 
			</div>
		)}
	</>
}
// End of Text Component

// Start of Reflection Component
export const ElementReflectionCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			description: "",
			is_shared: "shared",
		}}
		validate={values => {
			const errors = {};

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
				<RadioGroup
					aria-label="module"
					onChange={e => setFieldValue("is_shared", e.target.value, false)}
					value={values.is_shared ?? "shared"}
					row
				>
					<label htmlFor={"is_shared"} className="btn pl-0 mb-0">Response Content:</label>
					<FormControlLabel
						id={"is_shared"}
						name={"is_shared"}
						value="shared"
						control={<Radio />}
						label="Shared"
						className="p-0 m-0"
					/>
					<FormControlLabel
						id={"is_shared"}
						name={"is_shared"}
						value="private"
						control={<Radio />}
						label="Private"
						className="p-0 m-0"
					/>

				</RadioGroup>
					
				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success d-block mx-auto"
				>
					Submit
				</button>
			</form>
		)}
	</Formik>
})

export const ElementReflectionView = ({ attachment, element, params }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/reflection_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.context.is_shared === "shared" ? "Shared: ": "Private: "} How does this apply to you?
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) ? <div className="row">
			<div className="col-lg-9">
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{attachment.description}
				</Typography>
			</div>
			<div className="col-lg-3 d-flex align-items-center justify-content-center">
				<ShowResponses params={params} element={element} attachment={attachment} owner={true} />
			</div>
		</div> : <div className="d-flex align-items-center justify-content-center">
			<ShowResponses params={params} element={element} attachment={attachment} owner={true} />
		</div>}
	</>
}
// End of Reflection Component

// Start of Signature Component
export const ElementSignatureCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			title: ""
		}}
		validate={values => {
			const errors = {};

			return errors;
		}}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			create_owner_element_attachment(values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
				className="text-center"
			>
  
			  <div className="form-group">
				  <TextField
					  label="Signature Header"
					  color="secondary"
					  name="title"
					  placeholder="Confirmation needed"
					  margin="normal"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.title}
					  helperText={touched.title && errors.title}
					  error={Boolean(touched.title && errors.title)}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			  <div className="form-group">
				  <TextField
					  label="Description"
					  color="secondary"
					  multiline
					  name="description"
					  placeholder="Please input your COMPLETE NAME or SIGNATURE below to signify that you have read and agree with the terms above."
					  margin="normal"
					  variant="outlined"
					  onBlur={handleBlur}
					  onChange={handleChange}
					  value={values.description}
					  helperText={touched.description && errors.description}
					  error={Boolean(touched.description && errors.description)}
					  InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
					  InputLabelProps={{
						  shrink: true,
					  }}
				  />
			  </div>
  
			  <button
				  type="submit"
				  disabled={isSubmitting}
				  className="btn btn-success d-block mx-auto"
			  >
				  Submit
			  </button>
		  </form>
		)}
	</Formik>
})
  
export const ElementSignatureView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Signature" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/signature_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}}>
					{attachment.description}
				</Typography> 
			</div>
		)}
	</>
}
  // End of Signature Component

// Start of Survey Component
export const ElementSurveyCreate = injectIntl(({ params, element, intl, element_index, elements, setElements }) => {
	return <Formik
		initialValues={{
			library_party_id: params.library_party_id,
			module_resource_id: params.module_resource_id,
			lesson_resource_id: params.lesson_resource_id,
			element_type_id: element.element_type_id,
			element_id: element.id,
			file: null,
			title: "",
			description: "",
			choices: [{ description: "" }, { description: "" }],
			explanation_description: "",
			require_explanation: false
		}}
		validationSchema={yup.object().shape({
			choices: yup.array().of(yup.object().shape(
				{ description: yup.string().required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"})) }
			))
		})}
		onSubmit={(values, { setSubmitting, resetForm }) => {
			let new_values = { ...values };
			new_values.choices = JSON.stringify(values.choices);
			create_owner_element_attachment(new_values).then(({ data: { new_element_attachment } }) => {
				if(new_element_attachment) {
					let newElements = [...elements];
					newElements[element_index].attachments.push(new_element_attachment);
					setElements([]);
					setElements(newElements);
					window.scrollTo(0,document.body.scrollHeight);
				}
				setSubmitting(false);
			}).catch(err => {
				console.log(err);
				setSubmitting(false);
			});
		}}
	>
		{({
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			handleReset
		}) => (
			<form
				onSubmit={handleSubmit}
				noValidate
				autoComplete="off"
			>
				<div className="form-group">
					<TextField
						label="Title"
						color="secondary"
						multiline
						name="title"
						placeholder="Let's take a short survey..."
						margin="normal"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.title}
						helperText={touched.title && errors.title}
						error={Boolean(touched.title && errors.title)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<div className="form-group">
					<TextField
						label="Description"
						color="secondary"
						multiline
						name="description"
						placeholder="Description is not required and could be multiple lines..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.description}
						helperText={touched.description && errors.description}
						error={Boolean(touched.description && errors.description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>

				<FieldArray 
					name="choices"
					render={(choices) => {
						return <div>
							{values.choices.map((indiv_choice, choice_index) => {
								return <div key={choice_index}>
									<div className="form-group mb-2 d-flex align-items-center">
										<TextField
											label={`Choice ${choice_index + 1}`}
											color="secondary"
											multiline
											name={`choices.${choice_index}.description`}
											placeholder={`Input choice ${choice_index + 1}`}
											margin="normal"
											variant="outlined"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.choices[choice_index].description}
											helperText={
												touched.choices &&
												touched.choices[choice_index] &&
												touched.choices[choice_index].description &&
												errors.choices && 
												errors.choices[choice_index] && 
												errors.choices[choice_index].description
											}
											error={Boolean(
												touched.choices &&
												touched.choices[choice_index] &&
												touched.choices[choice_index].description &&
												errors.choices && 
												errors.choices[choice_index] && 
												errors.choices[choice_index].description
											)}
											InputLabelProps={{
												shrink: true,
											}}
										/>

										<button 
											type="button"
											className="btn text-danger"
											onClick={() => {
												if(values.choices.length > 2) {
													choices.remove(choice_index);
												}
											}}
										>
											Delete
										</button>
									</div>
								</div>
							})}
							
							<button
								type="button"
								className="btn btn-info d-block ml-auto"
								onClick={() => choices.push({ description: "" })}
							>
								Add More Choices
							</button>
						</div>
					}}
				/>

				{/* <div className="d-flex align-items-center mt-3">
					<Typography>
						Require Explanation?:
					</Typography>
					<FormControlLabel
						className="m-0"
						control={
							<Switch
								onClick={e => setFieldValue("require_explanation", !values.require_explanation)}
								checked={values.require_explanation}
							/>
						}
						label={values.require_explanation && "Yes"}
					/>
				</div> */}


				{/*values.require_explanation && <div className="form-group">
					<TextField
						label="Survey Explanation"
						color="secondary"
						multiline
						name="explanation_description"
						placeholder="Please explain your answer..."
						margin="normal"
						variant="outlined"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.explanation_description}
						helperText={touched.explanation_description && errors.explanation_description}
						error={Boolean(touched.explanation_description && errors.explanation_description)}
						InputProps={{ style: { minHeight: 100, alignItems: "flex-start" } }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</div>*/}

				<button
					type="submit"
					disabled={isSubmitting}
					className="btn btn-success d-block mx-auto"
				>
					Submit
				</button>
			</form>
		)}
	</Formik>
})

export const ElementSurveyView = ({ attachment }) => {
	return <>
		<div className="d-flex align-items-center mb-3">
			<img 
				alt="Reflection" 
				src="https://lernnex-stg.s3.amazonaws.com/element_type_icon/survey_icon.png" 
				style={{ maxHeight: 50 }}
			/>
			<Typography variant="h6" className="font-weight-bolder">
				{attachment.title || "Let's take a Survey"}
			</Typography> 
		</div>

		<hr />

		{Boolean(attachment.description) && (
			<div>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="font-weight-bolder">
					Description:
				</Typography>
				<Typography variant="body1" style={{whiteSpace : "pre-line"}} className="mb-3">
					{attachment.description}
				</Typography> 
			</div>
		)}

		<div className="row">
			<div className="col-lg-4 d-flex align-items-center justify-content-center">
				<div>
					<Typography variant="h6" className="font-weight-bold mb-3">
						Choices:
					</Typography>
					<ol style={{ listStyleType: "square" }}>
						{attachment.attachment_additionals.map((indiv_additional, additinal_index) => {
							return <li key={additinal_index} className="mb-3">
								<Typography variant="h6">
									{indiv_additional.description} ({indiv_additional.responses && indiv_additional.responses.length ? indiv_additional.responses.length : 0})
								</Typography>
							</li>
						})}
					</ol>
				</div>
			</div>
			<div className="col-lg-8 d-flex align-items-center justify-content-center">
				<div className="w-100">
					<DoughnutChart 
						chartLabel="Survey Data" 
						dataLabel={attachment.attachment_additionals.map(indiv_additional => {
							return indiv_additional.description;
						})}
						data={attachment.attachment_additionals.map(indiv_additional => {
							return indiv_additional.responses ? indiv_additional.responses.length : 0;
						})}
					/>
				</div>
			</div>
		</div>

		{Boolean(attachment.context.require_explanation) && <>
			<hr />
			<Typography className="mb-3">
				<span className="font-weight-bold">Explanation Required: </span>{attachment.context.require_explanation ? "Yes" : "No"}
			</Typography>
			<Typography className="font-weight-bold">
				Description:
			</Typography>
			<Typography>
				{attachment.context.explanation_description}
			</Typography>
		</>}
	</>
}
// End of Survey Component